import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form } from 'antd';
import styled from 'styled-components';
import { DangerText, PushNotification } from 'components';
import {
    AuthPopup,
    FirebaseService,
    NotificationStatus,
    NotificationText,
} from 'utility';
import { PrimaryButton, FormTextFormField } from 'elements';

const LoginSchema = yup.object().shape({
    email: yup.string().required('*Email is required'),
});

const CommanAuthScreenLogin = styled.div`
    background: #121212; /* Updated for dark theme */
    color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;

    .ant-form-item {
        margin-bottom: 15px;
    }

    .text-center {
        color: #00ccff;
        cursor: pointer;
    }

    .text-center:hover {
        text-decoration: underline;
    }
`;

export const ForgotPassword = ({ toggleModal, showGuestCheckout }) => {
    const [processing, setProcessing] = useState('');
    const [error, setError] = useState('');

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(LoginSchema),
    });

    const submit = async (formData) => {
        try {
            setError('');
            setProcessing('Processing');
            await FirebaseService.resetPassword(formData.email);
            PushNotification(NotificationText.ResetPasswordLinkSend, NotificationStatus.success);
            toggleModal(AuthPopup.login, null, showGuestCheckout); // Pass back showGuestCheckout
        } catch (err) {
            console.error('Error:', err);
            setError('Invalid Email Address');
        } finally {
            setProcessing('');
        }
    };

    return (
        <div className="position-relative">
            <CommanAuthScreenLogin>
                <Form>
                    <Form.Item>
                        <FormTextFormField
                            control={control}
                            label="Email"
                            name="email"
                            placeholder="Email"
                            errors={errors?.email}
                            defaultValue=""
                        />
                    </Form.Item>
                    {error && (
                        <Form.Item>
                            <DangerText>{error}</DangerText>
                        </Form.Item>
                    )}
                    <Form.Item>
                        <PrimaryButton
                            block
                            type="submit"
                            onClick={handleSubmit(submit)}
                            loading={!!processing}
                        >
                            Send Recovery Email
                        </PrimaryButton>
                    </Form.Item>
                    <Form.Item className="text-center">
                        <a
                            onClick={() => toggleModal(AuthPopup.login, null, showGuestCheckout)} // Pass showGuestCheckout back
                            className="text-center"
                        >
                            Back to Login
                        </a>
                    </Form.Item>
                </Form>
            </CommanAuthScreenLogin>
        </div>
    );
};
