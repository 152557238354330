export class BrowserUtility {
    static save(key, value) {
        window.localStorage.setItem(key, value)
    }

    static get(key) {
        return window.localStorage.getItem(key)
    }

    static saveObj(key, obj) {
        window.localStorage.setItem(key, JSON.stringify(obj))
    }

    static getObj(key) {
        const temp = window.localStorage.getItem(key)
        if (temp) {
            return JSON.parse(temp)
        }
        return null
    }

    static remove(key) {
        window.localStorage.removeItem(key)
    }

    static removeAll() {
        window.localStorage.clear()
    }

    static saveCookieWithExpiry(name, value, minutes) {
        const now = new Date()
        now.setTime(now.getTime() + minutes * 60 * 1000)
        const expires = `expires=${now.toUTCString()}`
        document.cookie = `${name}=${encodeURIComponent(
            JSON.stringify(value),
        )}; ${expires}; path=/`
    }

    static getCookieByName(name) {
        const nameEQ = `${name}=`
        const cookiesArray = document.cookie.split(';')
        for (let i = 0; i < cookiesArray.length; i += 1) {
            const cookie = cookiesArray[i].trim()
            if (cookie.indexOf(nameEQ) === 0) {
                return JSON.parse(
                    decodeURIComponent(cookie.substring(nameEQ.length)),
                )
            }
        }
        return null
    }
}
