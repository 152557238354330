import { GetCategoryList, GetCountryList, GetEventProviderList, GetFAQList, GetImageCategoryList, GetLanguageList, GetTCList, GetTimeZoneList } from 'hooks';
import React, { createContext, useContext, useMemo } from 'react'
import { useAuth } from './auth';

const EventContext = createContext();

export const EventProvider = ({ children }) => {
    const { countryCode } = useAuth()
    const { data: countryList } = GetCountryList();
    const { data: languageList } = GetLanguageList();
    const { data: categoryList } = GetCategoryList();
    const { data: imageCategoryList } = GetImageCategoryList();
    const { data: providerList, addProvider } = GetEventProviderList()
    const { data: tcList } = GetTCList()
    const { data: faqList } = GetFAQList()
    const { data: timeZoneList, refetch: refetchTimeZoneList } = GetTimeZoneList(countryCode)

    const contextData = useMemo(() => ({
        countryCode, countryList, categoryList, languageList, imageCategoryList, providerList, addProvider, tcList, faqList, timeZoneList, refetchTimeZoneList,
    }), [
        countryCode, countryList, categoryList, languageList, imageCategoryList, providerList, addProvider, tcList, faqList, timeZoneList, refetchTimeZoneList,
    ])
    return (
        <EventContext.Provider value={contextData}>
            <>
                {children}
            </>
        </EventContext.Provider>
    )
}

export const useEvent = () => useContext(EventContext)
