import { APIPath } from 'utility'
import { BaseService } from './base'

class UserQR {
    getActivated(urlCode) {
        const url = `${APIPath.getvalidateurl}/${urlCode}`
        return BaseService.get(url)
    }

    addActivatedCode(activationCode, urlCode) {
        const url = `${APIPath.addvalidateurl}?activationCode=${activationCode}&urlCode=${urlCode}`
        return BaseService.post(url)
    }
}

const ActivatedService = new UserQR()
Object.freeze(ActivatedService)
export { ActivatedService }
