import { APIPath } from 'utility'
import { BaseService } from './base'

class StripeConfig {
    getStripeConfig(id) {
        return BaseService.get(`${APIPath.paymentGateway}?TicketBucketId=${id}`)
    }
}

const StripeConfigService = new StripeConfig()
Object.freeze(StripeConfigService)
export { StripeConfigService }