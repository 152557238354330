import { PushNotification } from 'components'
import { BuyTicketPage } from 'page-components/ticket'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    BrowserUtility,
    NotificationStatus,
    NotificationText,
    TicketService,
} from 'utility'

export const BuyTicketScreen = () => {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { id } = useParams()
    const onSubmit = async (tickets, ticketRate) => {
        const data = Object.values(tickets || {}).filter(d => d)
        if (data.length === 0) return
        const IsSeatOption = data.filter(d => d.IsSeatOption).length > 0
        const params = data.map(ticket => ({
            EventTicketId: ticket.EventTicketId,
            Qty: ticket.Qty,
        }))
        setLoading(true)
        try {
            const result = await TicketService.selectTicket(params)
            const { TicketBucketId } = result.Result
            setLoading(false)
            if (IsSeatOption) {
                BrowserUtility.saveObj(`event-ticket-rate-${id}`, ticketRate)
                navigate(`/ticket/select-seats/${TicketBucketId}`)
            } else {
                BrowserUtility.remove(`hold-token-${id}`)
                navigate(`/ticket/confirmation/${TicketBucketId}`)
            }
        } catch (error) {
            console.log(error)
            PushNotification(
                error?.error?.ErrorMessage || NotificationText.defaultError,
                NotificationStatus.error,
            )
            setLoading(false)
        }
    }
    return (
        <div>
            <BuyTicketPage onSubmit={onSubmit} loading={loading} />
        </div>
    )
}
