import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Table, Form, Popconfirm } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import * as yup from 'yup'
import { FlexRowBetween, ProductContainer, PushNotification } from 'components'
import {
    FilePreviewer,
    FileUploadPicker,
    FormCheckBoxField,
    FormTagField,
    FormTextAreaFormField,
    FormTextFormField,
    OutlinedButton,
    PrimaryButton,
} from 'elements'
import {
    AcceptFileType,
    FileUploadService,
    NotificationStatus,
    NotificationText,
    ProductService,
} from 'utility'
import { GetProductLibrary, GetProductLibraryById } from 'hooks/product'
import { useAuth } from 'context'
import { Trash } from 'phosphor-react'

const UploadBoxWrapper = styled.div`
    width: 120px;
    height: 120px;
    border: 2px dashed #dcdcdc;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    margin-top: 8px;
`

const EditIcon = styled(EditOutlined)``

const ProductSchema = yup.object().shape({
    Name: yup.string().required('*Name is required'),
    Id: yup.string().required('*PID is required').nullable(),
    Categories: yup.array().min(1).required('*Category is required'),
    Price: yup
        .number()
        .typeError('*Price must be a number')
        .required('*Price is required')
        .positive('*Price must be a positive number')
        .nullable(),
    Description: yup.string().required('*Description is required'),
    Shippable: yup.boolean(),
});
export const Products = () => {
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const { data, refetch, setData } = GetProductLibrary()
    const { data: editData, filterChanged } = GetProductLibraryById()
    const [files, setFiles] = useState([])
    const { profile } = useAuth()
    const [proccessing, setProccessing] = useState('')
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(ProductSchema),
    })

    const upload = async () => {
        setLoading(true)
        const filesArray = files
        let result = []
        try {
            const promises = filesArray.map(async item => {
                if (item.preview) {
                    return { Image: item.ImageURL }
                }
                const { Result } = await FileUploadService.media(
                    item.file,
                    item.file.type,
                )
                return { Image: Result }
            })
            result = await Promise.all(promises)
            return result
        } catch (error) {
            PushNotification(
                NotificationText.defaultError,
                NotificationStatus.error,
            )
        }
        setLoading(false)
        return []
    }

    useEffect(() => {
        if (editData?.ProductUId) {
            setFiles(
                editData.ProductImages?.map(ele => ({
                    ImageId: ele.ImageId,
                    preview: true,
                    name: ele.ImageId,
                    Image: ele.ImageURL,
                    ImageURL: ele.ImageURL,
                })) || [],
            )
            reset({
                ...editData,
                Categories: editData.Categories.map(ele => ele.CategoryName),
            })
        }
    }, [editData])

    const addPreviewImage = newFiles => {
        setFiles([...newFiles])
    }

    const handleEdit = record => {
        filterChanged({ ProductUId: record.ProductUId })
    }
    const submit = async formData => {
        setLoading(true)
        try {
            const filesUrl = await upload()
            const reqData = {
                ...formData,
                ProductId: formData.ProductId || 0,
                Categories: formData.Categories.map(ele => ({
                    CategoryName: ele,
                })),
                ProductImages: filesUrl.map(ele => ele.Image),
                Images: undefined,
                TaxAmount: undefined,
                Qty: undefined,
                CurrencyCode: undefined,
                TeamId: profile.Id,
            }
            await ProductService.addProduct(reqData)
            reset()
            setFiles([])
            PushNotification(
                NotificationText.ItemAdded,
                NotificationStatus.success,
            )
            refetch()
        } catch (error) {
            PushNotification(
                NotificationText.defaultError,
                NotificationStatus.error,
            )
        } finally {
            setError('')
            setLoading(false)
        }
    }

    const removeFile = (name, id) => {
        if (id) {
            try {
                setProccessing(true)
                ProductService.deletePhoto(id)
                setFiles(files.filter(ele => ele.ImageId !== id))
            } catch (error) {
                console.log(error)
            } finally {
                setProccessing(false)
            }
            return
        }
        if (name) {
            const filesList = files?.filter(item => item.name !== name)
            setFiles(filesList)
        }
    }
    const deleteProduct = record => {
        try {
            setProccessing(true)
            ProductService.deleteProduct(record.ProductId)
            setData(data.filter(ele => ele.ProductId !== record.ProductId))
        } catch (error) {
            console.log(error)
        } finally {
            setProccessing(false)
        }
    }
    const columns = [
        {
            title: 'Name',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: 'ProductId',
            dataIndex: 'ProductId',
            key: 'ProductId',
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'Description',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <>
                    <EditIcon
                        className="mx-2 pointer"
                        onClick={() => handleEdit(record)}
                        size={24}
                    />
                    <Popconfirm
                        title="Delete the product"
                        description="Are you sure to revoke this product?"
                        onConfirm={() => deleteProduct(record)}
                        okText="Yes"
                        cancelButtonProps={<div />}
                        cancelText="No"
                    >
                        <Trash className="mx-2 pointer" size={20} />
                    </Popconfirm>
                </>
            ),
        },
    ]

    return (
        <div className="position-relative row d-flex justify-content-center mb-3 mt-3">
            <div className="col-12 ml-2 mr-2 box-layout">
                <ProductContainer>
                    <Form className="">
                        <div className="row">
                            <FlexRowBetween>
                                <h1 className="mt-0">Product Details</h1>
                            </FlexRowBetween>
                            <Form.Item className="col-12 col-sm-12 col-md-6">
                                <FormTextFormField
                                    control={control}
                                    name="Name"
                                    placeholder="Product Name"
                                    label="Name"
                                    errors={errors?.Name}
                                    defaultValue=""
                                    disabled={loading}
                                />
                            </Form.Item>
                            <Form.Item className="col-12 col-sm-12 col-md-6">
                                <FormTextFormField
                                    control={control}
                                    name="Id"
                                    placeholder="Product Id"
                                    label="Id"
                                    errors={errors?.Id}
                                    defaultValue=""
                                    disabled={loading}
                                />
                            </Form.Item>
                            <Form.Item className="col-12 col-sm-12 col-md-6">
                                <FormTagField
                                    control={control}
                                    name="Categories"
                                    placeholder="Product Category"
                                    label="Category"
                                    mode="multiple"
                                    errors={errors?.Categories}
                                    disabled={loading}
                                />
                            </Form.Item>
                            <Form.Item className="col-12 col-sm-12 col-md-6">
                                <FormTextFormField
                                    control={control}
                                    name="Price"
                                    placeholder="Product Price"
                                    label="Price"
                                    type="number"
                                    errors={errors?.Price}
                                    defaultValue=""
                                    disabled={loading}
                                />
                            </Form.Item>
                            <Form.Item className="col-12 col-sm-12 col-md-12">
                                <FormTextAreaFormField
                                    control={control}
                                    name="Description"
                                    placeholder="Product Description"
                                    label="Description"
                                    errors={errors?.Description}
                                    defaultValue=""
                                    disabled={loading}
                                />
                            </Form.Item>
                            <Form.Item className="col-12 col-sm-12 col-md-12">
                                <FormCheckBoxField
                                    control={control}
                                    name="Shippable"
                                    placeholder="Product Description"
                                    label="Shippable"
                                    errors={errors?.Shippable}
                                />
                            </Form.Item>
                            <Form.Item className="col-12 col-sm-12 col-md-6">
                                Images
                                <UploadBoxWrapper>
                                    <FileUploadPicker
                                        description={
                                            <h6>Upload Event Image</h6>
                                        }
                                        maxFiles={500}
                                        customButton={
                                            <OutlinedButton
                                                type="button"
                                                className=""
                                            >
                                                Browse
                                            </OutlinedButton>
                                        }
                                        accept={AcceptFileType.image}
                                        setFiles={list => {
                                            addPreviewImage(list)
                                        }}
                                        files={files}
                                        uploadText=""
                                        containerHeight="100"
                                        containerWidth="100"
                                    />
                                </UploadBoxWrapper>
                            </Form.Item>
                            <div className="mt-2">
                                <FilePreviewer
                                    files={files || []}
                                    removeFile={(name, id) =>
                                        removeFile(name, id)
                                    }
                                />
                            </div>
                        </div>
                        <p className="col d-flex justify-content-end mt-3">
                            <OutlinedButton
                                className="mx-2"
                                fluid="true"
                                onClick={() => {
                                    reset({ Name: '' }, setFiles([]))
                                }}
                                type="submit"
                            >
                                Reset
                            </OutlinedButton>
                            <PrimaryButton
                                className=""
                                fluid="true"
                                onClick={handleSubmit(submit)}
                                type="submit"
                                loading={loading || proccessing}
                            >
                                Save
                            </PrimaryButton>
                        </p>
                        {error && (
                            <p className="col d-flex justify-content-end">
                                <span className="error-message">{error}</span>
                            </p>
                        )}
                    </Form>
                    <Table
                        dataSource={data}
                        columns={columns}
                        rowKey="ProductUId"
                        loading={loading}
                    />
                </ProductContainer>
            </div>
        </div>
    )
}
