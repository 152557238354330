import { useState, useEffect, useMemo } from 'react'
import { SponserTableService } from 'utility'

export const GetSponserTableListByEvent = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [filter, setFilter] = useState({
        eventTicketId: undefined,
    })
    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await SponserTableService.getTableByEvent(filter)
            setData(result.Result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (!loading && filter?.eventTicketId) {
            fetchData()
        }
    }, [filter])

    const refetch = () => {
        fetchData()
    }

    const filterChanged = changeValues => {
        setFilter({
            ...changeValues,
        })
    }
    const fetchMoreData = () => {
        setFilter({
            ...filter,
        })
    }

    return {
        data,
        error,
        setData,
        loading,
        refetch,
        fetchMoreData,
        filterChanged,
    }
}

export const GetSponserTableList = id => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [filter, setFilter] = useState({
        ProviderEventId: id,
    })
    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await SponserTableService.list(filter)
            setData(
                result.Result.EventSponsorTable.map(ele => ({
                    ...ele,
                    AvailableTickets: ele.TableLimit - ele.TotalAssigned,
                })),
            )
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (!loading && id) {
            fetchData()
        }
    }, [filter, id])

    const refetch = () => {
        fetchData()
    }

    const filterChanged = changeValues => {
        setFilter({
            ...changeValues,
        })
    }
    const fetchMoreData = () => {
        setFilter({
            ...filter,
        })
    }

    return {
        data,
        error,
        setData,
        loading,
        refetch,
        fetchMoreData,
        filterChanged,
    }
}

export const GetSponserTableListByEventAndType = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [filter, setFilter] = useState({
        eventTicketId: undefined,
    })
    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await SponserTableService.getTableByEvent(filter)
            setData(result.Result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (!loading && filter?.eventTicketId) {
            fetchData()
        }
    }, [filter])

    const refetch = () => {
        fetchData()
    }

    const filterChanged = changeValues => {
        setFilter({
            ...changeValues,
        })
    }
    const fetchMoreData = () => {
        setFilter({
            ...filter,
        })
    }

    return {
        data,
        error,
        setData,
        loading,
        refetch,
        fetchMoreData,
        filterChanged,
    }
}

export const GetSeatPosition = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const options = useMemo(
        () =>
            data?.map(ele => ({
                label: ele.Description,
                value: ele.Position,
            })) || [],
        [data],
    )
    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await SponserTableService.getSeatPosition()
            setData(result.Result)
        } catch (err) {
            setError(err.message || 'An error occurred')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const refetch = () => {
        fetchData()
    }

    return {
        data,
        options,
        error,
        setData,
        loading,
        refetch,
    }
}

export const GetEventCategory = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const options = useMemo(
        () => data?.map(ele => ({ label: ele.Name, value: ele.Id })),
        [data],
    )
    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await SponserTableService.getEventCategory()
            setData(result.Result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (!loading) {
            fetchData()
        }
    }, [])

    const refetch = () => {
        fetchData()
    }

    return {
        data,
        options,
        error,
        setData,
        loading,
        refetch,
    }
}
