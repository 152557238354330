import { useAuth } from 'context'
import { useState, useEffect, useMemo } from 'react'
import { CouponTableService } from 'utility'

export const GetCouponTypeList = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const options = useMemo(
        () =>
            data?.map(ele => ({
                label: ele.CouponType,
                value: ele.UniqueId,
            })) || [],
        [data],
    )
    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await CouponTableService.getCouponType()
            setData(result.Result)
        } catch (err) {
            setError(err.message || 'An error occurred')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const refetch = () => {
        fetchData()
    }

    return {
        data,
        options,
        error,
        setData,
        loading,
        refetch,
    }
}

export const GetCoupon = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const { profile } = useAuth()
    const [hasMore, setHasMore] = useState(false)
    const [total, setTotal] = useState(0)
    const [filter, setFilter] = useState({
        pageNumber: 1,
        pageSize: 9,
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await CouponTableService.getCoupon(profile?.Id, {
                    ...filter,
                })
                if (filter.pageNumber === 1) {
                    setData(result.Result.EventCoupon)
                } else {
                    setData([...data, ...result.Result.EventCoupon])
                }
                setTotal(result.Result.TotalCount)
                setHasMore(result.Result.EventCoupon.length === filter.pageSize)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }

        if (!loading) {
            fetchData()
        }
    }, [filter, profile])

    const filterChanged = changeValues => {
        setFilter({
            ...changeValues,
            pageSize: 9,
            pageNumber: 1,
        })
    }
    const pageChanged = (page) => {
        setFilter({
            ...filter,
            pageSize: 9,
            pageNumber: page,
        })
    }
    const fetchMoreData = () => {
        setFilter({
            ...filter,
            pageNumber: filter.pageNumber + 1,
        })
    }

    const refetch = () => {
        setFilter({
            pageSize: 9,
            pageNumber: 1,
        })
    }

    return {
        data,
        error,
        setData,
        loading,
        pageChanged,
        fetchMoreData,
        hasMore,
        filter,
        filterChanged,
        total,
        refetch,
    }
}
