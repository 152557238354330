import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import { CustomScrollY, ImageWithFallback } from './Common'
import InfiniteScroll from 'react-infinite-scroll-component'
import { AppListSkeleton } from './Placeholder'
import { Link, useNavigate } from 'react-router-dom'
import { Checkbox, Dropdown, Tag } from 'antd'
import { OutlinedButton } from 'elements'
import { GearSix, Trash, Heart, MapPin } from 'phosphor-react'
import { AuthPopup, DateUtility, DayJSDateFormat, EventsService } from 'utility'
import { useAuth } from 'context'
import { useLayout } from 'layout'
// import { PopUpModal } from './PopUpModal'
// import TicketFormScreen from 'screens/sponsor/ticket-form'
const { Button: DropButton } = Dropdown

const CardLayout = styled.div`
    overflow: visible;
    cursor: pointer;
    .disabled {
        pointer-events: none;
    }
    .hover-container {
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        border-radius: 1rem;
        .ant-btn-default {
            background: ${({ theme }) => theme.colors.primary};
            justify-content: center;
            align-items: center;
            border: 0;
        }
        .ant-dropdown-button {
            border: 0;
        }
        .edit-delete {
            display: none;
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            button {
                display: inline-flex;
            }
            .view-sold-ticket {
                ${
                    '' /* padding: 8px 16px;
                font-weight: 500;
                font-size: 16px;
                color: ${({ theme }) => theme.colors.white} !important;
                background: ${({ theme }) => theme.colors.gray};
                border-radius: 0.5rem;
                margin: auto;
                width: 200px;
                text-align: center; */
                }
            }
        }
        &:hover {
            background: rgba(69, 74, 85, 0.3);
            .edit-delete {
                display: inline-flex;
            }
            .title {
                color: ${({ theme }) => theme.colors.white} !important;
            }
            .category {
                color: ${({ theme }) => theme.colors.white} !important;
            }
            .place {
                color: ${({ theme }) => theme.colors.white} !important;
            }
        }
    }
    .img-container {
        ${'' /* position: relative; */}
        ${
            '' /* height: ${({ imgHeight }) => (!imgHeight ? 250 : imgHeight)}px; */
        }
        ${'' /* width: 360px; */}
        img {
            width: 100%;
            object-fit: cover;
            overflow: hidden;
            aspect-ratio: 16/9;
            border-radius: 1rem 1rem 0rem 0rem;
        }
    }
    .tag-freeEvent {
        position: absolute;
        top: 3%;
        right: 0%;
        padding-block: 1px;
        padding-inline: 8px;
        border: 0;
        cursor: pointer;
    }

    .cancelled {
        position: absolute;
        top: 2%;
        left: 2%;
        padding-block: 1px;
        padding-inline: 8px;
        cursor: pointer;
        border: 0;
        border-radius: 0.5rem;
        background: #d44456;
    }

    .title {
        color: #b9bbbf !important;
        font-weight: 700;
        font-size: 18px;
        margin-left: 4.5px;
    }
    .category {
        color: #a2a5aa !important;
        font-size: 14px;
        display: flex !important;
        align-items: center !important;
        span {
            font-weight: 500;
            font-size: 14px;
            color: #ffffff;
        }
    }
    .place {
        color: #a2a5aa !important;
        font-size: 14px;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between;
        margin-left: 4.5px;
        .price {
            backdrop-filter: blur(2px);
            border-radius: 6px;
            width: fit-content;
            padding: 3px 6px;
            background: ${({ theme }) => theme.colors.danger};
        }
        .free-event {
            background: ${({ theme }) => theme.colors.success};
        }
        .date {
            color: #a6a6a6;
        }
    }
    position: relative;
    border: none !important;
`
const CardDetails = styled.div`
    margin-top: -10px;
    padding: 16px;
    font-weight: 500;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.white} !important;
    background: ${({ theme }) => theme.colors.darkGray};
    border-radius: 0 0 1rem 1rem;
`
const EventListContainer = styled.div`
    .home-btn {
        width: 200px;
        padding: 12px;
        background: #ff384e;
        border-radius: 12px;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        cursor: pointer;
        margin: 30px auto 50px;
    }
`
const Container = styled(InfiniteScroll)`
    height: 58.7rem;
    overflow: auto;
    background-size: cover;
    ${CustomScrollY};
`
const EventContainer = styled.div`
    margin-bottom: 32px;
    .event-card {
        height: 100%;
        border-radius: 1rem;
        background: ${({ theme }) => theme.colors.darkGray};
    }
`
const NoEvent = styled.div`
    margin: 100px auto;
    font-size: 48px;
    font-weight: normal;
    text-align: center;
`
export const EventItemCard = ({
    event,
    editMode = false,
    openDeleteModal,
    disableHover = false,
    imgHeight,
    checkBox,
    goToDetailPage,
    onSelectEvent,
    selected,
    refreshList,
    currencySign,
}) => {
    const { isAuthenticated } = useAuth()
    const { toggleModal } = useLayout()
    const navigate = useNavigate()
    const addRemoveFavorite = async e => {
        e.preventDefault()
        e.stopPropagation()
        if (isAuthenticated) {
            if (event.IsFavorite) {
                await EventsService.removeFavoriteEvent(event.ProviderEventId)
            } else {
                await EventsService.addFavoriteEvent(event.ProviderEventId)
            }
            if (refreshList) refreshList(event)
        } else {
            toggleModal(AuthPopup.login, null, true)
        }
    }

    // const [open, setOpen] = useState(false)

    // const onClose = () => {
    //     setOpen(false)
    // }
    const gotoSoldTicket = (e, id) => {
        e.stopPropagation()
        navigate(`/events/sold-ticket/${id}`)
    }
    const inviteUser = (e, id) => {
        e.stopPropagation()
        navigate(`/events/invite-users/${id}`)
    }
    const assignSeats = (e, id) => {
        e.stopPropagation()
        navigate(`/events/assign-seats/${id}`)
    }
    const items = useMemo(() => {
        return [
            {
                label: (
                    <Link
                        to={`/sponsor/${event.ProviderEventId}`}
                        className="mr-2"
                    >
                        <span className="view-sold-ticket">Ticket Center</span>
                    </Link>
                ),
                value: 'Sponsors',
                key: 'Sponsors',
            },
            {
                label: (
                    <div
                        className=""
                        onClick={e => gotoSoldTicket(e, event.ProviderEventId)}
                    >
                        View Sold Ticket
                    </div>
                ),
                value: 'View Sold Ticket',
                key: 'View Sold Ticket',
            },
            {
                label: (
                    <div
                        className=""
                        onClick={e => assignSeats(e, event.ProviderEventId)}
                    >
                        Assign Seats
                    </div>
                ),
                value: 'Assign Seats',
                key: 'Assign Seats',
            },
            {
                label: (
                    <div
                        className=""
                        onClick={e => inviteUser(e, event.ProviderEventId)}
                    >
                        Invite Users
                    </div>
                ),
                value: 'Invite Users',
                key: 'Invite Users',
            },
            {
                label: (
                    <div className="" onClick={e => onMenuClick(e, event)}>
                        Cancel
                    </div>
                ),
                value: 'Delete',
                key: 'Delete',
            },
        ]
    }, [])

    const MenuTypes = {
        Delete: 'delete',
        ViewSoldTicket: 'view-sold-ticket',
        Sponsors: 'sponsors',
    }
    const onMenuClick = (e, event) => {
        e.stopPropagation()
        if (MenuTypes.Delete) {
            openDeleteModal(event)
        }
    }

    return (
        <>
            <CardLayout
                imgHeight={imgHeight}
                onClick={() => {
                    if (typeof onSelectEvent === 'function') {
                        onSelectEvent()
                    } else if (goToDetailPage) {
                        goToDetailPage()
                    }
                }}
            >
              <div className="img-container" style={{ position: 'relative' }}>
    <ImageWithFallback
        className="img"
        src={event.EventImage || 'https://picsum.photos/500/200/?blur=2'}
        alt="event"
    />

    {/* Favorite Icon */}
    {!editMode && (
        <Tag
            className="tag-freeEvent"
            style={{
                position: 'absolute',
                top: '5%', // Position the favorite icon
                right: '5%',
                zIndex: 10, // Ensure it is above the image
            }}
            onClick={addRemoveFavorite}
        >
            <Heart
                size={40}
                color={event.IsFavorite ? '#FF384E' : '#fff'}
                weight={event.IsFavorite ? 'fill' : 'duotone'}
            />
        </Tag>
    )}

    {/* Ticket Price at the Bottom of the Image */}
    {event.TicketPrice > 0 && (
        <div
            className="price-tag"
            style={{
                position: 'absolute',
                bottom: '5%', // Positioned at the bottom of the image
                right: '5%',
                backgroundColor: '#ffd700', // Golden background for price
                color: '#000', // Black text for contrast
                fontWeight: 'bold',
                padding: '5px 10px',
                borderRadius: '5px',
                fontSize: '12px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                zIndex: 9, // Ensure it is above the image
            }}
        >
            {event.TicketPrice === event.TicketMaxPrice
                ? `${currencySign}${event.TicketPrice}`
                : `${currencySign}${event.TicketPrice} - ${currencySign}${event.TicketMaxPrice}`}
        </div>
    )}

    {/* Cancelled Tag */}
    {event.IsCancelled && (
        <Tag
            className="cancelled"
            style={{
                position: 'absolute',
                top: '5%',
                left: '5%',
                zIndex: 10, // Ensure it is above the image
            }}
        >
            <span>Cancelled</span>
        </Tag>
    )}
</div>
                <CardDetails>
    <div className="title mt-2">{event.EventName}</div>

    {/* Tags for CategoryName and Language */}
    <div className="tags mt-2" style={{ display: 'flex', gap: '5px' }}>
        {event.CategoryName && (
            <Tag
                style={{
                    backgroundColor: '#f0f8ff', // Light blue for CategoryName
                    color: '#004085', // Darker blue for contrast
                    borderRadius: '4px',
                    padding: '2px 6px', // Reduced height
                    fontWeight: 'bold',
                    fontSize: '12px',
                    border: '1px solid #b8daff', // Subtle border for CategoryName
                }}
            >
                {event.CategoryName}
            </Tag>
        )}
        {event.Language && (
            <Tag
                style={{
                    backgroundColor: '#fffbea', // Light yellow for Language
                    color: '#856404', // Darker brown for contrast
                    borderRadius: '4px',
                    padding: '2px 6px', // Reduced height
                    fontWeight: 'bold',
                    fontSize: '12px',
                    border: '1px solid #ffeeba', // Subtle border for Language
                }}
            >
                {event.Language}
            </Tag>
        )}
    </div>

    {/* Price, Address, and Date Section */}
    <div
    className="place mt-2"
    style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10px',
    }}
>
    {/* Left Section: Price and Address */}
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: 'calc(100% - 80px)' }}>
    {/* PlaceName */}
    {event.PlaceName && (
        <div
            className="place-name"
            style={{
                display: 'flex',
                alignItems: 'center',
                color: '#FFD700', // Bright gold for place name
                fontSize: '16px',
                fontWeight: 'bold',
            }}
        >
            <span>{event.PlaceName}</span>
        </div>
    )}

    {/* Address */}
    <div
        className="category"
        style={{
            display: 'flex',
            alignItems: 'center',
            color: '#B0BEC5', // Soft blue-gray for the address
            fontSize: '14px',
        }}
    >
        <MapPin size={18} color="#FF6F61" style={{ marginRight: '4px' }} /> {/* Coral red for the icon */}
        <span>{event.Address || event.EventAddress}</span>
    </div>
</div>

    {/* Right Section: Date */}
    <div
        className="date-container"
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            width: '70px',
            height: '70px',
            borderRadius: '8px',
            overflow: 'hidden',
        }}
    >
        {/* Day Section */}
        <div
            className="date-day"
            style={{
                backgroundColor: '#F5F5F5', // Light gray for Day
                color: '#000',
                height: '50%', // Equal height for Day
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '20px',
                fontWeight: 'bold',
                width: '100%',
            }}
        >
            {DateUtility.getDateTime(event.EventStartDateTime, 'DD')}
        </div>

        {/* Month Section */}
        <div
            className="date-month"
            style={{
                backgroundColor: '#D44456', // Dark blue for Month
                color: '#fff',
                height: '50%', // Equal height for Month
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                width: '100%',
            }}
        >
            {DateUtility.getDateTime(event.EventStartDateTime, 'MMM')}
        </div>
    </div>
</div>
</CardDetails>

                {editMode && (
                    <>
                        <div
                            className={`hover-container ${
                                disableHover && 'disabled'
                            }`}
                        >
                            <div className="edit-delete">
                                <DropButton menu={{ items }}>
                                    <span>
                                        <Link
                                            to={`/events/edit/${event.EventURL}`}
                                        >
                                            <GearSix size={20} />
                                        </Link>
                                    </span>
                                </DropButton>
                                {false && (
                                    <>
                                        <Link
                                            to={`/sponsor/${event.ProviderEventId}`}
                                            className="mr-2"
                                            // onClick={() => {
                                            //     // setOpen(event.ProviderEventId)
                                            // }}
                                        >
                                            <OutlinedButton>
                                                <span className="view-sold-ticket">
                                                    Sponsors
                                                </span>
                                            </OutlinedButton>
                                        </Link>
                                        <Link
                                            to={`/events/sold-ticket/${event.ProviderEventId}`}
                                        >
                                            <OutlinedButton>
                                                <span className="view-sold-ticket">
                                                    View Sold Ticket
                                                </span>
                                            </OutlinedButton>
                                        </Link>
                                        <Link
                                            to={`/events/assign-seats/${event.ProviderEventId}`}
                                        >
                                            <OutlinedButton>
                                                <span className="view-sold-ticket">
                                                    Assign Seats
                                                </span>
                                            </OutlinedButton>
                                        </Link>
                                        <Link
                                            to={`/events/invite-users/${event.ProviderEventId}`}
                                        >
                                            <OutlinedButton>
                                                <span className="view-sold-ticket">
                                                    Invite Users
                                                </span>
                                            </OutlinedButton>
                                        </Link>
                                        <OutlinedButton
                                            className="ml-2"
                                            onClick={e => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                openDeleteModal(event)
                                            }}
                                        >
                                            <Trash size={22} />
                                        </OutlinedButton>
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                )}
                {checkBox && (
                    <div className="d-flex justify-content-end">
                        <Checkbox checked={selected} onClick={onSelectEvent} />
                    </div>
                )}
            </CardLayout>
            {/* <PopUpModal
                title="Sponsor Ticket"
                open={open}
                onCancel={() => onClose()}
            >
                <div>
                    <TicketFormScreen ProviderEventId={open} />
                </div>
            </PopUpModal> */}
        </>
    )
}

export const EventItemQrViewCard = ({
    event,
    editMode = false,
    openDeleteModal,
    disableHover = false,
    imgHeight,
    checkBox,
    goToDetailPage,
    onSelectEvent,
    selected,
    refreshList,
    result: CurrencySign,
}) => {
    const { isAuthenticated } = useAuth()
    const { toggleModal } = useLayout()
    const navigate = useNavigate()
    const addRemoveFavorite = async e => {
        e.preventDefault()
        e.stopPropagation()
        if (isAuthenticated) {
            if (event.IsFavorite) {
                await EventsService.removeFavoriteEvent(event.ProviderEventId)
            } else {
                await EventsService.addFavoriteEvent(event.ProviderEventId)
            }
            if (refreshList) refreshList(event)
        } else {
            toggleModal(AuthPopup.login, null, true)
        }
    }

    // const [open, setOpen] = useState(false)

    // const onClose = () => {
    //     setOpen(false)
    // }
    const gotoSoldTicket = (e, id) => {
        e.stopPropagation()
        navigate(`/events/sold-ticket/${id}`)
    }
    const inviteUser = (e, id) => {
        e.stopPropagation()
        navigate(`/events/invite-users/${id}`)
    }
    const assignSeats = (e, id) => {
        e.stopPropagation()
        navigate(`/events/assign-seats/${id}`)
    }
    const items = useMemo(() => {
        return [
            {
                label: (
                    <Link
                        to={`/sponsor/${event.ProviderEventId}`}
                        className="mr-2"
                    >
                        <span className="view-sold-ticket">Ticket Center</span>
                    </Link>
                ),
                value: 'Sponsors',
                key: 'Sponsors',
            },
            {
                label: (
                    <div
                        className=""
                        onClick={e => gotoSoldTicket(e, event.ProviderEventId)}
                    >
                        View Sold Ticket
                    </div>
                ),
                value: 'View Sold Ticket',
                key: 'View Sold Ticket',
            },
            {
                label: (
                    <div
                        className=""
                        onClick={e => assignSeats(e, event.ProviderEventId)}
                    >
                        Assign Seats
                    </div>
                ),
                value: 'Assign Seats',
                key: 'Assign Seats',
            },
            {
                label: (
                    <div
                        className=""
                        onClick={e => inviteUser(e, event.ProviderEventId)}
                    >
                        Invite Users
                    </div>
                ),
                value: 'Invite Users',
                key: 'Invite Users',
            },
            {
                label: (
                    <div className="" onClick={e => onMenuClick(e, event)}>
                        Cancel
                    </div>
                ),
                value: 'Delete',
                key: 'Delete',
            },
        ]
    }, [])

    const MenuTypes = {
        Delete: 'delete',
        ViewSoldTicket: 'view-sold-ticket',
        Sponsors: 'sponsors',
    }
    const onMenuClick = (e, event) => {
        e.stopPropagation()
        if (MenuTypes.Delete) {
            openDeleteModal(event)
        }
    }

    return (
        <>
            <CardLayout
                imgHeight={imgHeight}
                onClick={() => {
                    if (typeof onSelectEvent === 'function') {
                        onSelectEvent()
                    } else if (goToDetailPage) {
                        goToDetailPage()
                    }
                }}
            >
                <div className="img-container">
                    <ImageWithFallback
                        className="img"
                        src={
                            event.EventImage ||
                            'https://picsum.photos/500/200/?blur=2'
                        }
                        alt="event"
                    />
                    {!editMode && (
                        <Tag
                            className="tag-freeEvent"
                            onClick={addRemoveFavorite}
                        >
                            <Heart
                                size={40}
                                color={event.IsFavorite ? '#FF384E' : '#fff'}
                                weight={event.IsFavorite ? 'fill' : 'duotone'}
                            />
                        </Tag>
                    )}
                    {event.IsCancelled && (
                        <Tag className="cancelled" onClick={addRemoveFavorite}>
                            <span>Cancelled</span>
                        </Tag>
                    )}
                </div>
                <CardDetails>
                    <div className="title mt-2">{event.EventName}</div>
                    <div className=" category mt-2">
                        <MapPin size={30} color="#FF384E" weight="fill" />
                        <span>{event.Address || event.EventAddress}</span>
                    </div>
                    <div className="place mt-2">
                        {event.TicketPrice || event.IsFreeEvent ? (
                            <div
                                className={`price ${
                                    event.IsFreeEvent && 'free-event'
                                }`}
                            >
                                {event.IsFreeEvent
                                    ? 'FREE'
                                    : `${CurrencySign}${event.TicketPrice} /per person`}
                            </div>
                        ) : (
                            <div />
                        )}
                        <div className="date">
                            {DateUtility.getDateTime(
                                event.EventStartDateTime,
                                DayJSDateFormat.dayMonth,
                            )}
                        </div>
                    </div>
                </CardDetails>
                {editMode && (
                    <>
                        <div
                            className={`hover-container ${
                                disableHover && 'disabled'
                            }`}
                        >
                            <div className="edit-delete">
                                <DropButton menu={{ items }}>
                                    <span>
                                        <Link
                                            to={`/events/edit/${event.EventURL}`}
                                        >
                                            <GearSix size={20} />
                                        </Link>
                                    </span>
                                </DropButton>
                                {false && (
                                    <>
                                        <Link
                                            to={`/sponsor/${event.ProviderEventId}`}
                                            className="mr-2"
                                            // onClick={() => {
                                            //     // setOpen(event.ProviderEventId)
                                            // }}
                                        >
                                            <OutlinedButton>
                                                <span className="view-sold-ticket">
                                                    Sponsors
                                                </span>
                                            </OutlinedButton>
                                        </Link>
                                        <Link
                                            to={`/events/sold-ticket/${event.ProviderEventId}`}
                                        >
                                            <OutlinedButton>
                                                <span className="view-sold-ticket">
                                                    View Sold Ticket
                                                </span>
                                            </OutlinedButton>
                                        </Link>
                                        <Link
                                            to={`/events/assign-seats/${event.ProviderEventId}`}
                                        >
                                            <OutlinedButton>
                                                <span className="view-sold-ticket">
                                                    Assign Seats
                                                </span>
                                            </OutlinedButton>
                                        </Link>
                                        <Link
                                            to={`/events/invite-users/${event.ProviderEventId}`}
                                        >
                                            <OutlinedButton>
                                                <span className="view-sold-ticket">
                                                    Invite Users
                                                </span>
                                            </OutlinedButton>
                                        </Link>
                                        <OutlinedButton
                                            className="ml-2"
                                            onClick={e => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                openDeleteModal(event)
                                            }}
                                        >
                                            <Trash size={22} />
                                        </OutlinedButton>
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                )}
                {checkBox && (
                    <div className="d-flex justify-content-end">
                        <Checkbox checked={selected} onClick={onSelectEvent} />
                    </div>
                )}
            </CardLayout>
            {/* <PopUpModal
                title="Sponsor Ticket"
                open={open}
                onCancel={() => onClose()}
            >
                <div>
                    <TicketFormScreen ProviderEventId={open} />
                </div>
            </PopUpModal> */}
        </>
    )
}

export const EventList = ({
    events,
    goToDetail,
    fetchMoreData,
    hasMoreData,
    loading,
    imgHeight,
    editMode,
    refreshList,
    addGotoHome,
    hideGotoHomeBtn,
    qrView,
    currencySign,
}) => {
    const { loading: authLoading } = useAuth()
    const [init, setInit] = useState(false)
    const navigate = useNavigate()
    const clickOnEvent = event => {
        if (goToDetail) {
            goToDetail(event.ProviderEventId)
            return
        }
        navigate(`/events/${event.EventURL}`)
    }
    const goToHome = () => {
        navigate(`/`)
    }
    useEffect(() => {
        const to = setTimeout(() => setInit(true), 750)

        return () => {
            if (to) clearTimeout(to)
        }
    }, [])

    return (
        <EventListContainer className="row">
            <Container
                dataLength={events?.length}
                next={fetchMoreData}
                hasMore={hasMoreData}
                className="row"
            >
                {events?.map((item, i) => (
                    <EventContainer
                        className="col-12 col-md-6 col-sm-12 col-lg-4"
                        key={item.ProviderEventId + i + item.EventName}
                        onClick={() => clickOnEvent(item)}
                        pointer
                    >
                        <div className="event-card">
                            {qrView ? (
                                <EventItemQrViewCard
                                    event={item}
                                    goToDetail={goToDetail}
                                    imgHeight={imgHeight}
                                    pointer
                                    editMode={editMode}
                                    refreshList={refreshList}
                                />
                            ) : (
                                <EventItemCard
                                    event={item}
                                    currencySign={currencySign}
                                    goToDetail={goToDetail}
                                    imgHeight={imgHeight}
                                    pointer
                                    editMode={editMode}
                                    refreshList={refreshList}
                                />
                            )}
                        </div>
                    </EventContainer>
                ))}
                {(!init || loading) && (
                    <AppListSkeleton classStyle="col-12 col-md-4 col-sm-6 col-lg-4 mb-5" />
                )}
                {init && !authLoading && events?.length === 0 && !loading && (
                    <NoEvent>
                        No result found!
                        {!hideGotoHomeBtn && (
                            <div className="home-btn" onClick={goToHome}>
                                <span>Go to Home</span>
                            </div>
                        )}
                    </NoEvent>
                )}
            </Container>
            {!hideGotoHomeBtn && addGotoHome && events?.length > 0 && !loading && (
                <div className="home-btn" onClick={goToHome}>
                    <span>Go to Home</span>
                </div>
            )}
        </EventListContainer>
    )
}
