import { APIPath, CommonUtility } from 'utility'
import { BaseService } from './base'

class SponserTable {
    list(data) {
        const params = CommonUtility.objectToParams(data)
        return BaseService.get(`${APIPath.sponserTable}?${params}`)
    }

    add(data) {
        return BaseService.post(APIPath.sponserTable, data)
    }

    update(data) {
        return BaseService.post(APIPath.updateSponserTable, data)
    }

    remove(id) {
        return BaseService.post(`${APIPath.removeSponserTable}?TableId=${id}`)
    }

    getTableByEvent(data) {
        const params = CommonUtility.objectToParams(data)
        return BaseService.get(`${APIPath.tableByEvent}?${params}`)
    }

    getEventCategory() {
        return BaseService.get(`${APIPath.eventCategory}`)
    }

    getSeatPosition() {
        return BaseService.get(`${APIPath.SeatPosition}`)
    }
}

const SponserTableService = new SponserTable()
Object.freeze(SponserTableService)
export { SponserTableService }
