import { yupResolver } from '@hookform/resolvers/yup'
import { Checkbox, Divider, Form, Popconfirm, Table } from 'antd'
import { FlexRow, PushNotification } from 'components'
import { FormSelectionField, FormTagField, PrimaryButton } from 'elements'
import { GetAllTicketTypeByEvent, GetSponserRoleList, GetInvitedMembers } from 'hooks'
import { Trash } from 'phosphor-react'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { NotificationStatus, NotificationText, TeamService, theme } from 'utility'
import * as yup from 'yup'

const InviteUserContainer = styled.div`
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 10px;
    .title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }
    .ticket-type-box {
            background: ${({ theme }) => theme.colors.secondary};
            border-radius: 6px;
    }
    .ant-select-selector {
        height: 40px !important;
    }
        .ant-select-selection-placeholder,.ant-select-selection-item {
            display: flex;
            align-items: center;
        }
    .text-white {
        color: ${({ theme }) => theme.colors.white};
    }
`

const EventSchema = yup.object().shape({
    Emails: yup.array()
        .of(yup.string().email('Please enter valid emails'))
        .min(1, 'At least one email is required'),
    RoleId: yup.string().required("Role is required"),
})

const TicketTypeBox = ({ item, onSelect }) => <FlexRow title={item.TicketType} className="col-4 mb-2 pointer" onClick={() => onSelect(item.EventTicketId, !item.Selected)}>
    <FlexRow className="ticket-type-box p-2 col"><span className="me-2"><Checkbox checked={item.Selected} /></span><span className="title">{item.TicketType}</span></FlexRow>
</FlexRow>

export const InviteUsers = () => {
    const { id } = useParams()
    const [proccessing, setProccessing] = useState("")
    const { data: sponsersTicket, setData: setTicektTypeData } = GetAllTicketTypeByEvent(id)
    const { data: invitedUsers, refetch, setData } = GetInvitedMembers(id)
    const { data: roleOptions } = GetSponserRoleList()
    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(EventSchema),
    })

    const submit = async (formData) => {
        try {
            setProccessing(true)
            await TeamService.inviteMultipleMember({
                Email: formData.Emails?.toString().replaceAll(",", ";") || [],
                RoleId: formData.RoleId,
                TicketIds: sponsersTicket?.filter(ele => ele.Selected)?.map(ele => ele.EventTicketId).toString().replaceAll(",", ";") || [],
            })
            PushNotification("User invited", NotificationStatus.success)
        } catch {
            PushNotification(NotificationText.defaultError, NotificationStatus.error)
        } finally {
            setProccessing(false)
            reset({ Emails: [] })
            setTicektTypeData(sponsersTicket.map(ele => ({ ...ele, Selected: false })))
            refetch()
        }
    }
    const revoke = (data) => {
        try {
            setProccessing(true)
            TeamService.removeInvitedMember(data.InvitedId)
            setData(invitedUsers.filter(ele => ele.InvitedId !== data.InvitedId))
        } catch (error) {
            console.log(error)
        } finally {
            setProccessing(false)
        }
    }
    const columns = useMemo(() => [
        {
            title: 'Ticket Type',
            dataIndex: 'Email',
            key: 'Email',
        },
        {
            title: 'TicketType',
            dataIndex: 'TicketType',
            key: 'TicketType',
        },
        {
            title: 'Role',
            dataIndex: 'Role',
            key: 'Role',
        },
        {
            title: 'Role',
            dataIndex: 'RoleName',
            key: 'RoleName',
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            key: 'Actions',
            render: (_, record) => <><Popconfirm
                title="Delete the Item"
                description="Are you sure to revoke this tickets?"
                onConfirm={() => revoke(record)}
                okText="Yes"
                cancelButtonProps={<div />}
                cancelText="No"
            >
                <Trash className="mx-2 pointer" color={theme.colors.danger} size={20} />
            </Popconfirm></>,
        },
    ], [invitedUsers])
    const onSelectTicketType = (id, status) => {
        setTicektTypeData(sponsersTicket.map(ele => {
            if (ele.EventTicketId === Number(id)) {
                return {
                    ...ele,
                    Selected: status,
                }
            }
            return ele
        }))
    }
    return (
        <InviteUserContainer className="container p-4 mt-5">
            <h3>Invite User</h3>
            <Divider />
            <Form className="row">
                <Form.Item className="col-6">
                    <label className="text-white mb-2">Ticket Types</label>
                    <FlexRow className="flex-wrap row">{sponsersTicket.map(ele => <TicketTypeBox onSelect={onSelectTicketType} item={ele} />)}</FlexRow>
                </Form.Item>
                <Form.Item className="col-6">
                    <Form.Item className="col-12">
                        <FormTagField
                            name="Emails"
                            control={control}
                            label="Emails"
                            placeholder="Add Emails"
                            errors={{ message: errors?.Emails?.[0]?.message }}
                            open={false}
                        />
                    </Form.Item>
                    <Form.Item className="col-12">
                        <FormSelectionField
                            name="RoleId"
                            control={control}
                            placeholder="Role"
                            options={roleOptions}
                            label="Role"
                            errors={errors.RoleId}
                        />
                    </Form.Item>
                </Form.Item>
            </Form>
            <div className="d-flex justify-content-end"><PrimaryButton loading={proccessing} onClick={handleSubmit(submit)}>Save</PrimaryButton></div>
            <div className="mt-4 col">
                <Table
                    columns={columns}
                    dataSource={invitedUsers}
                    keyField="EventTicketId"
                    size="small"
                />
            </div>
        </InviteUserContainer>
    )
}
