import { GetPublicSummary, useQueryString } from 'hooks'
import React, { useEffect } from 'react'
import { SummaryType } from 'utility'
import { OfferSummary, TicketDetailScreen } from './ticket'

export const GCSummary = () => {
    const query = useQueryString()
    const { data, filterChanged, loading } = GetPublicSummary()
    useEffect(() => {
        filterChanged({
            ...query,
        })
    }, [query])
    return (
        <div>
            {SummaryType.Event === query.Type ? (
                <TicketDetailScreen
                    publicLoading={loading}
                    publicData={data}
                    showRedeem
                />
            ) : (
                <OfferSummary publicLoading={loading} publicData={data} />
            )}
        </div>
    )
}
