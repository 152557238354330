import { yupResolver } from '@hookform/resolvers/yup'
import { Checkbox, Divider, Form } from 'antd'
import { FlexRowBetween, FlexRowWrap } from 'components'
import { FormSelectionField, FormTextAreaFormField, FormTextFormField, OutlinedButton, PrimaryButton } from 'elements'
import { GetEventDetails } from 'hooks'
import { DraggableAppTable } from 'page-components'
import { Plus, Trash } from 'phosphor-react'
import React, { useMemo, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import * as yup from 'yup'

const AssignSeatsContainer = styled.div`
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 10px;
        .ant-select-selection-placeholder,.ant-select-selection-item {
            display: flex;
            align-items: center;
        }
`

const AssignSeatsSchema = yup.object().shape({
    Seats: yup.array().of(
        yup.object().shape({
            RowName: yup.string(),
            SeatId: yup.string(),
            Status: yup.string(),
            SeatNo: yup.string(),
        }),
    ),
    RowSeat: yup.array().of(
        yup.object().shape({
            id: yup.string(),
            row: yup.string().required('Row is Required'),
            seat: yup.string().required('*Seat Number is Required'),
        }),
    ),
    Name: yup.string(),
    Ticket: yup.string(),
})

export const AssignSeatsScreen = () => {
    const { id } = useParams()
    const { data } = GetEventDetails(id)
    const options = useMemo(() => data?.EventTicketRate?.map(ele => ({ value: ele.EventTicketId, label: ele.TicketType })), [data])
    const [tickets, setTicktes] = useState([])

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(AssignSeatsSchema),
        defaultValues: {
            FreeTicketAge: 2,
        },
    })
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'RowSeat',
    })
    const addNew = () => {
        append({ id: '', row: '', seat: '' })
    }
    const onTicketSelect = (ticket) => {
        let list = [...tickets]
        if (list.includes(ticket.EventTicketId)) {
            list = list.filter(ele => ele !== ticket.EventTicketId)
        } else {
            list.push(ticket.EventTicketId)
        }
        setTicktes(list)
    }
    const submit = (formData) => {
        console.log("formData", formData)

    }
    const columns = useMemo(() => [
        {
            title: ' ',
            dataIndex: ' ',
            render: (_, ticketData) => <span className="align-items-center d-flex justify-content-center">
                <Checkbox
                    checked={tickets?.includes(ticketData?.EventTicketId)}
                    onClick={() => onTicketSelect(ticketData)}
                />
            </span>,
        },
        {
            title: 'Ticket Type',
            dataIndex: 'TicketType',
            key: 'TicketType',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Qty',
            dataIndex: 'TicketQty',
            key: 'TicketQty',
        },
        {
            title: 'Price',
            dataIndex: 'TicketPrice',
            key: 'TicketPrice',
        },
    ], [data?.EventTicketRate, tickets])
    return (
        <AssignSeatsContainer className="container p-4 mt-5">
            <h3>Assign Seats</h3>
            <Divider />
            <Form className="row">
                <Form.Item className="col-6">
                    <FormTextFormField
                        name="Name"
                        control={control}
                        placeholder="Name"
                        label="Name"
                        errors={errors.Name}
                    />
                </Form.Item>
                <Form.Item className="col-6">
                    <FormSelectionField
                        name="Ticket"
                        control={control}
                        placeholder="Ticket"
                        options={options}
                        label="Ticket"
                        errors={errors.Ticket}
                    />
                </Form.Item>
                <Form.Item className="col-12">
                    <FlexRowBetween className="col-12 align-items-center">
                        <OutlinedButton onClick={addNew}>
                            <Plus className="mr-3" />
                            <span className="mx-2">
                                Add Row
                            </span>
                        </OutlinedButton>
                    </FlexRowBetween>
                    {fields.map((item, index) => {
                        return (
                            <Form.Item
                                key={`seat-details-${index}`}
                                className={`row-seat-${index}`}
                            >
                                <div className="row">
                                    <div className="col-3">
                                        <FormTextFormField
                                            control={control}
                                            label="Row"
                                            name={`RowSeat.${index}.row`}
                                            placeholder="A"
                                            errors={
                                                errors
                                                    ?.RowSeat?.[
                                                    index
                                                ]?.row
                                            }
                                        />
                                    </div>
                                    <div className="col-9">
                                        <FlexRowWrap className="row">
                                            <div className="col">
                                                <FormTextFormField
                                                    control={control}
                                                    label="Seat"
                                                    name={`RowSeat.${index}.seat`}
                                                    placeholder="10-20,30"
                                                    errors={
                                                        errors
                                                            ?.RowSeat?.[
                                                            index
                                                        ]?.seat
                                                    }
                                                />
                                            </div>
                                            <div className="col-1 mt-4 pt-2">
                                                <PrimaryButton onClick={() => remove(index)}><Trash /></PrimaryButton>
                                            </div>
                                        </FlexRowWrap>
                                    </div>
                                </div>
                            </Form.Item>
                        )
                    })}
                    <FormTextAreaFormField
                        control={control}
                        label="Seat Number"
                        name="SeatNo"
                        placeholder="A10-A20,10,30"
                        errors={errors?.SeatNo}
                    />
                </Form.Item>
                <div className="d-flex justify-content-end">
                    <PrimaryButton onClick={handleSubmit(submit)}>Save</PrimaryButton>
                </div>
            </Form>
            <div className="mt-4 col">
                <DraggableAppTable
                    keyField={
                        !data.ProviderEventId ? 'tempId' : 'EventTicketId'
                    }
                    columns={columns}
                    data={[]}
                    size="small"
                /></div>
        </AssignSeatsContainer>
    )
}
