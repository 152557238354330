import { useState,useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { BrowserUtility, OfferCartService, OrderService, TicketService } from "utility"

export const GetPublicKey = TicketBucketId => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await TicketService.getPublicKey({ TicketBucketId })
            setData(result?.Result)
            BrowserUtility.saveObj('public-key', result?.Result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        const details = BrowserUtility.getObj('public-key')
        if (details) setData(details)
    }, [])

    return {
        data,
        error,
        loading,
        fetchData,
    }
}
export const GetOfferPublicKey = TicketBucketId => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await OfferCartService.getPublicKey(TicketBucketId)
            setData(result?.Result)
            BrowserUtility.saveObj('offer-public-key', result?.Result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        fetchData()
        const details = BrowserUtility.getObj('offer-public-key')
        if (details) setData(details)
    }, [])

    return {
        data,
        error,
        loading,
        fetchData,
    }
}

export const MyOrders = tense => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await OrderService.myOrders({
                tense,
            })
            setData(result?.Result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (!loading) {
            fetchData()
        }
    }, [tense])

    return {
        data,
        error,
        loading,
    }
}

export const OrderDetails = (PurchaseId,PurchaseType) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await OrderService.detail({ PurchaseId,PurchaseType })
            setData(result?.Result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (!loading && PurchaseId) {
            fetchData()
        }
    }, [PurchaseId])

    return {
        data,
        error,
        loading,
    }
}

export const SelectSeat = params => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await TicketService.selectTicket(params)
            setData(result?.Result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (!loading) {
            fetchData()
        }
    }, [params])

    return {
        data,
        error,
        loading,
    }
}
export const BookNow = TicketBucketId => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await TicketService.bookNow({ TicketBucketId })
            setData(result?.Result)
            BrowserUtility.saveObj(`book-now-${TicketBucketId}`, result?.Result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (TicketBucketId) {
            const details = BrowserUtility.getObj(`book-now-${TicketBucketId}`)
            if (!details) fetchData()
            else setData(details)
        }
    }, [])

    return {
        data,
        error,
        loading,
        fetchData,
    }
}

export const OfferBuyNow = TicketBucketId => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await OfferCartService.buyNow(TicketBucketId)
            setData(result?.Result)
            BrowserUtility.saveObj(`book-now-${TicketBucketId}`, result?.Result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (TicketBucketId) {
            const details = BrowserUtility.getObj(`book-now-${TicketBucketId}`)
            if (!details) fetchData()
            else setData(details)
        }
    }, [])

    return {
        data,
        error,
        loading,
        fetchData,
    }
}

export const OrderSummary = id => {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await TicketService.orderSummary({
                TicketBucketId: id,
            })
            setData(result?.Result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (!loading) {
            fetchData()
        }
    }, [id])

    return {
        data,
        error,
        loading,
        fetchData,
    }
}
export const OrderConfirmation = id => {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await TicketService.orderConfirmation({
                PaymentIntentId: id,
                CreateSeperateTicket: false,
            })
            setData(result?.Result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (!loading) {
            fetchData()
        }
    }, [id])

    return {
        data,
        error,
        loading,
    }
}

export const OrderFreeConfirmation = ticketBucketId => {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await TicketService.orderFreeConfirmation({
                TicketBucketId: ticketBucketId,
                CreateSeperateTicket: false,
            })
            setData(result?.Result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (!loading) {
            fetchData()
        }
    }, [ticketBucketId])

    return {
        data,
        error,
        loading,
    }
}

export const OfferOrderSummary = (id, redirect = true) => {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const navigate = useNavigate()

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await OfferCartService.getconfirmOrder({
                ProductBucketId: id,
                CheckPaymentStatus: false,
            })
            setData(result?.Result)
        } catch (error) {
            if (redirect) {
                navigate('/')
            }
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (!loading) {
            fetchData()
        }
    }, [id])

    return {
        data,
        error,
        loading,
        fetchData,
    }
}