import { APIPath } from 'utility'
import { BaseService } from './base'

class PublicSummary {
    getPublicSummary(reqData) {
        return BaseService.post(`${APIPath.GTPublicSummary}`, reqData)
    }
}

const PublicSummaryService = new PublicSummary()
Object.freeze(PublicSummaryService)
export { PublicSummaryService }
