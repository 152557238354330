import React, { useEffect } from 'react';
import {
    EventName,
    DescSection,
    Description,
    DateLocationSection,
    DateWapper,
    WapperIcon,
    WapperMain,
    WapperSub,
} from './style';
import location from '../../assets/Icons/Location.svg';
import calender from '../../assets/Icons/Calendar.svg';
import dollar from '../../assets/Icons/Dollar.svg';
import '../../screens/Css/EventDetail.css';
import { ButtonAndSocialLinks } from '../ticket/ButtonAndSocialLinks';
import { DateUtility, DayJSDateFormat, AuthPopup } from 'utility';
import { TermsAndCondition } from '../../page-components/events/TermsAndCondition';
import { FAQNew } from '../../page-components/events/FAQNew';
import { Advertisement } from './Advertisement';
import { useAuth } from 'context';
import { useLayout } from 'layout';

export const EventDetails = ({ data, refreshList, currencySign }) => {
    const { guest, setGuest, isAuthenticated } = useAuth();
    const { toggleModal } = useLayout();

    useEffect(() => {
        setGuest(false);
    }, [setGuest]);

    const handleBuyTicketClick = () => {
        if (!isAuthenticated && !guest) {
            toggleModal(AuthPopup.login, null, true);
        } else {
            console.log('Proceed to ticket purchase...');
        }
    };

    const flyerId = 13;
    const flyerImage = data?.EventImages?.find(ele => ele.ImageCategoryId === flyerId)?.Image;

    return (
        <div className="event-detail" style={{ backgroundColor: '#000000', color: '#f0f0f0', padding: '20px', borderRadius: '10px' }}>
            <div className="event-details-grid">
                {/* Priority Section: Buy Ticket + Scan Me */}
                <div className="priority-container">
                    {/* Buy Ticket Button */}
                    <div className="pt-2">
                        <ButtonAndSocialLinks
                            data={data}
                            refreshList={refreshList}
                            currencySign={currencySign}
                            onBuyTicket={handleBuyTicketClick}
                        />
                    </div>

                    {/* Advertisement Section */}
                    <div className="pt-2">
                        <Advertisement data={data} />
                    </div>
                </div>

                {/* Description Section */}
                <div className="description-container">
                    <EventName style={{ color: '#ffffff' }}>{data?.EventName}</EventName>

                    {/* Announcement Section */}
                    {data?.SpecialMessage && (
                        <div
                            className="announcement-container p-3 mb-3"
                            style={{
                                backgroundColor: '#333333',
                                border: '1px solid #666666',
                                borderRadius: '8px',
                                color: '#f0f0f0',
                            }}
                        >
                            <h4 className="announcement-title" style={{ color: '#ffd700' }}>Message</h4>
                            <p>{data.SpecialMessage}</p>
                        </div>
                    )}

                    <DescSection>
                        <input type="checkbox" id="check" />
                        {flyerImage && (
                            <img
                                className="col-12 mb-3 flyer-image"
                                src={flyerImage}
                                alt="Event flyer"
                            />
                        )}
                        <Description style={{ color: '#00ccff' }}>Description</Description>
                        <pre className="content shown" style={{ color: '#e0e0e0' }}>{data?.Description}</pre>
                    </DescSection>

                    <DateLocationSection>
                        <div className="row">
                            <DateWapper className="col-sm-12 col-md-12 pb-md-3">
                                <WapperIcon>
                                    <img src={calender} alt="Calendar icon" />
                                </WapperIcon>
                                <div className="details">
                                    <WapperMain style={{ color: '#ffffff' }}>
                                        {DateUtility.getDateTime(
                                            data?.EventStartDateTime,
                                            DayJSDateFormat.dateMonth,
                                        )}
                                    </WapperMain>
                                    <WapperSub style={{ color: '#cccccc' }}>
                                        {DateUtility.getEventTime(
                                            data?.EventStartDateTime,
                                            data?.ShowEndTime && data?.EventEndDateTime,
                                            'Onwards',
                                        )}
                                    </WapperSub>
                                </div>
                            </DateWapper>
                            <DateWapper className="col-sm-12 col-md-12 pb-md-3">
                                <WapperIcon>
                                    <img src={location} alt="Location icon" />
                                </WapperIcon>
                                <div className="details">
                                    <WapperMain style={{ color: '#ffffff' }}>{data?.PlaceName}</WapperMain>
                                    <WapperSub style={{ color: '#cccccc' }}>
    {(data?.FormattedAddress || data?.AddressLine1)?.replace(/,\s*$/, '')}
</WapperSub>

                                </div>
                            </DateWapper>
                            {data?.IsTicket && !data?.IsFreeEvent && (
                                <DateWapper className="col-sm-12 col-md-12 pb-md-3">
                                    <WapperIcon>
                                        <img src={dollar} alt="Dollar icon" />
                                    </WapperIcon>
                                    <div className="details">
                                        <span style={{ color: '#f0f0f0' }}>
                                            {currencySign} {data?.MinRatePrice || 0} - {currencySign} {data?.MaxRatePrice || 0}
                                        </span>
                                        <WapperSub style={{ color: '#cccccc' }}>
                                            {data?.ProviderRefundPolicy}
                                            <br />
                                            {data?.GtikitRefundPolicy}
                                        </WapperSub>
                                    </div>
                                </DateWapper>
                            )}
                            {data?.ExtraLink && (
                                <div className="view-more-link">
                                    <a href={data?.ExtraLink} target="_blank" rel="noopener noreferrer" style={{ color: '#00ccff' }}>
                                        View More
                                    </a>
                                </div>
                            )}
                        </div>
                    </DateLocationSection>

                    {/* Terms & Conditions Section */}
                    <div className="terms-condition">
                        <TermsAndCondition data={data} />
                    </div>

                    {/* FAQ Section */}
                    <div className="terms-condition pt-4">
                        <FAQNew data={data} />
                    </div>
                </div>
            </div>
        </div>
    );
};
