import { APIPath } from 'utility/constant'
import { BaseService } from './base'

class Guest {
    // getEvent(reqData) {
    //     const data = CommonUtility.objectToParams(reqData)
    //     return BaseService.get(`${APIPath.getEvent}?${data}`)
    // }

    addGuestCheckout(countryCode) {
        return BaseService.get(
            `${APIPath.GuestCheckout}?CountryCode=${countryCode}`,
        )
    }
}

const GuestService = new Guest()
Object.freeze(GuestService)
export { GuestService }
