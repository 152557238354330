import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Spin, Card, Typography } from 'antd';
import { FormTextFormField, PrimaryButton } from 'elements';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ActivatedService } from 'utility';
import { useParams } from 'react-router-dom';

const { Title, Text } = Typography;

const UserSchema = yup.object().shape({
    activationCode: yup.string().required('*Activation code is required'),
    urlCode: yup.string().required('*URL code is required'),
});

export const ActivateQRScreen = () => {
    const params = useParams();
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(UserSchema),
        defaultValues: {
            urlCode: params.id || '',
        },
    });

    const [activationLoading, setActivationLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    // Function to fetch activation status only if necessary
    const fetchActivateQR = async (id) => {
        try {
            const response = await ActivatedService.getActivated(id);
            return response.Result;
        } catch (err) {
            console.error("Error fetching activation data:", err);
            return null;
        }
    };

    useEffect(() => {
        // Check if the page has a valid parameter before triggering the API call
        if (params.id) {
            const checkActivationStatus = async () => {
                const activationData = await fetchActivateQR(params.id);
                if (activationData) {
                    window.location.href = activationData; // Redirect if activation data is found
                } else {
                    setShowForm(true); // Show the form if no activation data found
                }
                setLoading(false);
            };
            checkActivationStatus();
        } else {
            setShowForm(true); // Show form directly if there's no parameter
            setLoading(false);
        }
    }, [params.id]);

    const submit = async (formData) => {
        setActivationLoading(true);
        setErrorMessage('');
        try {
            // Add activation code
            await ActivatedService.addActivatedCode(formData.activationCode, formData.urlCode);

            // Fetch the activation result
            const getResponse = await ActivatedService.getActivated(formData.urlCode);
            if (getResponse.StatusCode === 200) {
                if (getResponse.Result) {
                    window.location.href = getResponse.Result;
                } else {
                    setErrorMessage('Please enter a valid activation code.');
                }
            } else {
                setErrorMessage('Unexpected response structure or error from the server.');
            }
        } catch (err) {
            setErrorMessage('An error occurred while activating the code.');
        } finally {
            setActivationLoading(false);
        }
    };

    // Show loading spinner while data is being fetched
    if (loading) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}>
                <Spin size="large" />
            </div>
        );
    }

    // If `showForm` is false, don't render the form (nothing will be displayed)
    if (!showForm) {
        return null;
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f5f5f5',
            padding: '20px',
        }}>
            <Card style={{
                maxWidth: '500px',
                width: '100%',
                padding: '40px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                textAlign: 'center',
                background: '#ffffff',
            }}>
                <Title level={2} style={{ marginBottom: '16px' }}>Activate Your QR Code</Title>
                <Text type="secondary">
                    Please enter the activation code and URL code to proceed.
                </Text>

                <Form layout="vertical" onFinish={handleSubmit(submit)}>
                    <div className="row">
                        <div className="col-12">
                            <Form.Item>
                                <FormTextFormField
                                    control={control}
                                    name="activationCode"
                                    placeholder="Enter activation code"
                                    label="Activation code"
                                    errors={errors.activationCode}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-12">
                            <Form.Item>
                                <FormTextFormField
                                    control={control}
                                    name="urlCode"
                                    placeholder="Enter URL code"
                                    label="URL code"
                                    errors={errors.urlCode}
                                    disabled={!!params.id}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '20px',
                    }}>
                        <PrimaryButton
                            loading={activationLoading}
                            htmlType="submit"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '10px 20px',
                                fontSize: '16px',
                                fontWeight: '500',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                                backgroundColor: '#1890ff',
                                color: '#ffffff',
                                border: 'none',
                                minWidth: '150px',
                                height: '45px',
                            }}
                        >
                            {activationLoading ? 'Activating...' : 'Activate'}
                        </PrimaryButton>
                    </div>

                    {errorMessage && (
                        <div style={{
                            marginTop: '20px',
                            color: '#a94442',
                            backgroundColor: '#f8d7da',
                            padding: '10px',
                            borderRadius: '4px',
                            textAlign: 'center',
                            fontWeight: '500',
                        }}>
                            {errorMessage}
                        </div>
                    )}

                <Text
                    type="secondary"
                    style={{
                        marginTop: '20px',
                        display: 'block',
                        fontSize: '14px',
                        color: '#666',
                    }}
                >
                    Powered by GTikit
                </Text>
                </Form>
            </Card>
        </div>
    );
};
