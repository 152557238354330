/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import { GetRedeemProductList } from 'hooks'
import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Card, Checkbox } from 'antd'
import { LoaderBar, PushNotification } from 'components'
import { PrimaryButton } from 'elements'
import {
    BrowserUtility,
    CommonUtility,
    DateFormat,
    DateUtility,
    NotificationStatus,
    NotificationText,
    RedeemService,
} from 'utility'
import { useAuth } from 'context'
import FailIcon from '../../assets/Fail.svg'
import WarningIcon from '../../assets/Warning.svg'
import SucessIcon from '../../assets/Success.svg'

// const MessageBanner = {
//     fail: {
//         title: 'Booking already done',
//         desc: 'Please check your QR code as the booking has already been done',
//     },
//     warning: {
//         title: 'Seats Pending',
//         desc: 'Some people from these booking have not taken seats yet.',
//     },
// }

const MsgBox = styled.div`
    position: relative;
    background-color: #ffffff;
    h6 {
        color: #000000;
    }
    .corner {
        position: absolute;
        width: 50px;
        height: 50px;
        background-color: #3498db;
        border-radius: 100%;
    }
    .corner.top-left {
        top: -14px;
        left: -14px;
    }
    .corner.top-right {
        top: -14px;
        right: -14px;
    }
    .corner.bottom-left {
        bottom: -14px;
        left: -14px;
    }
    .corner.bottom-right {
        bottom: -14px;
        right: -14px;
    }
    .title {
        color: ${({ status }) =>
            status === 'success'
                ? '#35C053'
                : status === 'fail'
                ? '#FF384E'
                : status === 'warning'
                ? '#FF9838'
                : '#FF384E'};
    }
`

// const BannerMsgBox = ({ status }) => {
//     return (
//         <MsgBox className="p-3 mb-2">
//             <div className="corner top-left" />
//             <div className="corner top-right" />
//             <div className="corner bottom-left" />
//             <div className="corner bottom-right" />
//             <strong className="title">{MessageBanner?.[status]?.title}</strong>
//             <h6 className="mt-2">{MessageBanner?.[status]?.desc}</h6>
//         </MsgBox>
//     )
// }
const BannerMsgBox = ({ status, data }) => {
    return (
        <MsgBox status={status} className="p-3 mb-2">
            <div className="corner top-left" />
            <div className="corner top-right" />
            <div className="corner bottom-left" />
            <div className="corner bottom-right" />
            <strong className="title">{data.Order?.Title}</strong>
            <h6 className="mt-2">{data.Order?.Message}</h6>
        </MsgBox>
    )
}

const Header = styled.div`
    color: white;
    padding-inline: 16px;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    position: relative;
    background-color: ${({ status }) =>
        status === 'success'
            ? '#35C053'
            : status === 'fail'
            ? '#FF384E'
            : status === 'warning'
            ? '#FF9838'
            : '#FF384E'};

    img {
        margin-right: 12px;
        margin-left: 3px;
    }

    span {
        margin-left: 5px;
    }
`

const Container = styled.div`
    h6 {
        font-size: 14px;
    }
    .corner {
        position: absolute;
        width: 30px;
        height: 30px;
        background-color: #d3d3d3;
        border-radius: 100%;
    }
    .bottom-left {
        left: -4px;
    }
    .bottom-right {
        right: -4px;
    }
    .top-right {
        right: -14px;
        top: -14px;
    }
    .top-left {
        left: -14px;
        top: -14px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #161d2a;
    .strikeout {
        position: relative;
    }
    .strikeout::before {
        content: '';
        position: absolute;
        top: 110%;
        left: 0;
        width: 100%;
        border-bottom: 1px dotted #cbb7b7;
    }
`

const StyledCard = styled(Card)`
    width: 100%;
    max-width: 600px;
    border-radius: 12px;
    overflow: hidden;
    background-color: #d3d3d3;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .ant-card-body {
        padding: 10px;
        background-color: #d3d3d3;
        height: 100vh;
    }
`

const EventInfo = styled.div`
    color: #fff;
    font-size: 16px;
    padding: 16px;
    .f-14 {
        font-size: 16px;
    }
    border-radius: 8px;
    background-color: ${({ status }) =>
        status === 'success'
            ? '#35C053'
            : status === 'fail'
            ? '#FF384E'
            : status === 'warning'
            ? '#FF9838'
            : '#FF384E'};
`

const TableContainer = styled.div`
    .header {
        background-color: ${({ status }) =>
            status === 'success'
                ? '#35C053'
                : status === 'fail'
                ? '#FF384E'
                : status === 'warning'
                ? '#FF9838'
                : '#FF384E'};
    }
    .table-header-border {
        border: solid 0px;
        border-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .table-border {
        border: solid 2px;
        border-radius: 10px;
        border-color: ${({ status }) =>
            status === 'success'
                ? '#35C053'
                : status === 'fail'
                ? '#FF384E'
                : status === 'warning'
                ? '#FF9838'
                : '#FF384E'} !important;
    }
    .white-color {
        color: #ffffff;
    }
    .black-color {
        color: #000000;
    }
    background-color: #ffffff;
    position: relative;
    border-radius: 8px;
    .strikeout {
        text-decoration: line-through;
    }
    .row {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: black;
        padding: 12px 16px;
        background-color: ${({ status }) =>
            status === 'success'
                ? '#E8F5E9'
                : status === 'fail'
                ? '#FBE9E7'
                : status === 'warning'
                ? '#FFEB3B'
                : '#FBE9E7'};
        border-bottom: 1px dotted #cbb7b7;
    }

    .row-content {
        text-decoration: none;
    }
`

const Summary = styled.div`
    display: flex;
    justify-content: space-between;
    //  background-color: #FF384E;
    background-color: ${({ status }) =>
        status === 'success'
            ? '#35C053'
            : status === 'fail'
            ? '#FF384E'
            : status === 'warning'
            ? '#FF9838'
            : '#FF384E'};
    border-radius: 8px;
    color: white;
    font-weight: bold;
`
const StyledPrimaryButton = styled(PrimaryButton)`
    background-color: ${({ status }) =>
        status === 'success'
            ? '#35C053'
            : status === 'fail'
            ? '#FF384E'
            : status === 'warning'
            ? '#FF9838'
            : '#FF384E'};
    color: white;
    border: none;
`
const StyledCheckbox = styled(Checkbox)`
    .ant-checkbox-inner {
        background-color: white;
        border-color: ${({ status }) =>
            status === 'success'
                ? '#35C053'
                : status === 'fail'
                ? '#FF384E'
                : status === 'warning'
                ? '#FF9838'
                : '#FF384E'};
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: white;
        border-color: ${({ status }) =>
            status === 'success'
                ? '#35C053'
                : status === 'fail'
                ? '#FF384E'
                : status === 'warning'
                ? '#FF9838'
                : '#FF384E'};
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: ${({ status }) =>
            status === 'success'
                ? '#35C053'
                : status === 'fail'
                ? '#FF384E'
                : status === 'warning'
                ? '#FF9838'
                : '#FF384E'};
    }

    .ant-checkbox:hover .ant-checkbox-inner {
        border-color: ${({ status }) =>
            status === 'success'
                ? '#35C053'
                : status === 'fail'
                ? '#FF384E'
                : status === 'warning'
                ? '#FF9838'
                : '#FF384E'} !important;
        background-color: white !important;
        border-color: white !important;
    }

    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: ${({ status }) =>
            status === 'success'
                ? '#35C053'
                : status === 'fail'
                ? '#FF384E'
                : status === 'warning'
                ? '#FF9838'
                : '#FF384E'} !important;
        box-shadow: none !important;
    }
`

export const RedeemListScreen = () => {
    const { id } = useParams()
    const { data, loading, setData, refetch } = GetRedeemProductList(id)
    const [proccessing, setProccessing] = useState(false)
    const { currencyCode } = useAuth()
    const [checked, setChecked] = useState([])
    const AvailableItems = useMemo(() => data?.Items?.AvailableItems, [data])
    const RedeemItems = useMemo(() => data?.Items?.RedeemItems, [data])
    const totalItems = useMemo(() => [
        ...(AvailableItems || []),
        ...(RedeemItems || []).map(ele => ({ ...ele, redeemed: true })),
    ])

    const setItem = id => {
        if (checked.includes(id)) {
            setChecked(checked.filter(ele => ele !== id))
        } else {
            setChecked([...checked, id])
        }
    }
    const status = useMemo(() => {
        if (!loading) {
            if (AvailableItems?.length && RedeemItems?.length) {
                return 'warning'
            }
            if (AvailableItems?.length && RedeemItems?.length === 0) {
                return 'success'
            }
            return 'fail'
        }
        return ''
    }, [AvailableItems, RedeemItems, loading])
    const Message = useMemo(() => {
        if (status === 'success') {
            return 'Ticket successfully scanned.'
        }
        if (status === 'fail') {
            return 'Ticket  Error!'
        }
        if (status === 'warning') {
            return 'Warning!'
        }
        return ''
    })
    const submit = async () => {
        if (!checked.length) {
            PushNotification('Please select items', NotificationStatus.error)
            return
        }
        const ids = checked
        try {
            setProccessing(true)
            await RedeemService.updateCheckIn({
                ProductDetailIds: ids.toString(),
                CheckInStatus: 1,
                CheckInMode: 3,
            })
            refetch()
            const RedeemItemsList = AvailableItems?.filter(
                ele => ids.includes(ele.ProductDetailId),
                // eslint-disable-next-line function-paren-newline
            )
            setData({
                ...data,
                Items: {
                    ...data.Items,
                    AvailableItems: AvailableItems.filter(
                        ele => !ids.includes(ele.ProductDetailId),
                    ),
                    RedeemItems: [...RedeemItems, ...RedeemItemsList],
                },
            })
            setChecked([])
            PushNotification('Items Redeemed', NotificationStatus.success)
        } catch {
            PushNotification(
                NotificationText.defaultError,
                NotificationStatus.error,
            )
        } finally {
            setProccessing(false)
        }
    }
    const navigate = useNavigate()
    const cartUrlData = JSON.parse(BrowserUtility.get('qr-cart'))
    return (
        <div className="">
            <Container>
                {loading ? (
                    <LoaderBar />
                ) : (
                    <StyledCard status={status}>
                        <EventInfo className="mb-2" status={status}>
                            <Header status={status}>
                                {status === 'success' && (
                                    <img
                                        src={SucessIcon}
                                        alt="Success"
                                        style={{ marginLeft: -15 }}
                                    />
                                )}
                                {status === 'fail' && (
                                    <img
                                        src={FailIcon}
                                        alt="Fail"
                                        style={{ marginLeft: -15 }}
                                    />
                                )}
                                {status === 'warning' && (
                                    <img
                                        src={WarningIcon}
                                        alt="Warning"
                                        style={{ marginLeft: -15 }}
                                    />
                                )}
                                <span className="ms-2">{Message}</span>
                            </Header>
                            <div className="mt-3 f-14">
                                <h6 style={{ fontWeight: 'bold' }}>
                                    {data?.Order?.Title || ''}
                                </h6>
                                {/* <h6>
                                    Event date:{' '}
                                    {DateUtility.dateToString(
                                        data?.Order?.PurchaseDate || new Date(),
                                        DateFormat.dateTime,
                                    )}
                                </h6> */}
                                <h6>
                                    Purchase On:{' '}
                                    {DateUtility.dateToString(
                                        data?.Order?.PurchaseDate || new Date(),
                                        DateFormat.dateTime,
                                    )}
                                    {/* // data?.Order?.CheckInDateTime ||
                                        //     new Date(),
                                        // DateFormat.time, */}
                                </h6>
                            </div>
                            <div className="corner bottom-left" />
                            <div className="corner bottom-right" />
                        </EventInfo>
                        {status !== 'success' && (
                            <BannerMsgBox status={status} data={data} />
                        )}
                        <TableContainer className="py-3 px-3" status={status}>
                            <div>
                                <div className="border table-border">
                                    <div className="d-flex  pointer table-header-border p-2  header mb-2">
                                        <div className="col-2">
                                            <StyledCheckbox
                                                style={{
                                                    color: '#35C053',
                                                    marginLeft: 8,
                                                }}
                                                checked={
                                                    checked.length &&
                                                    checked.length ===
                                                        AvailableItems.length
                                                }
                                                onClick={() =>
                                                    checked.length &&
                                                    checked.length ===
                                                        AvailableItems.length
                                                        ? setChecked([])
                                                        : setChecked(
                                                              AvailableItems.map(
                                                                  ele =>
                                                                      ele.ProductDetailId,
                                                              ),
                                                          )
                                                }
                                            />
                                        </div>
                                        <div className="col-6">
                                            <strong className="white-color ">
                                                Name
                                            </strong>
                                        </div>
                                        <div className="col-2">
                                            <strong className="white-color ">
                                                Qty.
                                            </strong>
                                        </div>
                                        <div className="col-2">
                                            <strong className="white-color ">
                                                Price
                                            </strong>
                                        </div>
                                    </div>
                                    {totalItems.map(ele => (
                                        <div
                                            key={ele.ProductDetailId}
                                            className={`d-flex mb-2 mx-2 px-2 pointer ${
                                                ele.redeemed ? 'strikeout' : ''
                                            }`}
                                            onClick={() =>
                                                !ele.redeemed &&
                                                setItem(ele.ProductDetailId)
                                            }
                                        >
                                            <div className="col-2">
                                                <StyledCheckbox
                                                    checked={checked.includes(
                                                        ele.ProductDetailId,
                                                    )}
                                                    style={{
                                                        color: '#35C053',
                                                        marginRight: 8,
                                                    }}
                                                    onClick={() =>
                                                        !ele.redeemed &&
                                                        setItem(
                                                            ele.ProductDetailId,
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="col-6 black-color">
                                                <div style={{ color: '#000' }}>
                                                    {ele.ItemName}
                                                </div>
                                                {/* {(status === 'fail' ||
                                                    status === 'warning') &&
                                                    ele.RedeemOn && (
                                                        <div
                                                            style={{
                                                                color: '#000',
                                                            }}
                                                        >
                                                            RedeemOn:{' '}
                                                            {DateUtility.dateToString(
                                                                ele.RedeemOn,
                                                                DateFormat.time,
                                                            )}
                                                        </div>
                                                    )}  */}
                                                {ele.IsRedeem && (
                                                    <>
                                                        {ele.RedeemOn && (
                                                            <div
                                                                style={{
                                                                    color: '#000',
                                                                }}
                                                            >
                                                                RedeemOn:{' '}
                                                                {DateUtility.dateToString(
                                                                    DateUtility.getDateTimeFromUTC(
                                                                        ele.RedeemOn,
                                                                        'America/New_York',
                                                                    ),
                                                                    DateFormat.time,
                                                                )}
                                                            </div>
                                                        )}
                                                        {ele.RedeemBy && (
                                                            <div
                                                                style={{
                                                                    color: '#000',
                                                                }}
                                                            >
                                                                RedeemBy:{' '}
                                                                {ele.RedeemBy}
                                                            </div>
                                                        )}
                                                        {ele.RedeemMethod && (
                                                            <div
                                                                style={{
                                                                    color: '#000',
                                                                }}
                                                            >
                                                                RedeemMethod:{' '}
                                                                {
                                                                    ele.RedeemMethod
                                                                }
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-2 black-color">
                                                {CommonUtility.numberWithCommas(
                                                    ele.Qty,
                                                )}
                                            </div>{' '}
                                            <div className="col-2 black-color">
                                                {CommonUtility.currencyFormat(
                                                    ele.TotalPrice,
                                                    currencyCode,
                                                )}
                                            </div>{' '}
                                        </div>
                                    ))}
                                </div>
                                <Summary className="p-2 mt-3" status={status}>
                                    <div>
                                        Total Qty:{' '}
                                        {CommonUtility.numberWithCommas(
                                            data?.Order?.TotalProduct,
                                        )}
                                    </div>
                                    <div>
                                        Total Price:{' '}
                                        {CommonUtility.currencyFormat(
                                            data?.Order?.FinalPrice,
                                            currencyCode,
                                        )}{' '}
                                    </div>
                                </Summary>
                            </div>
                            <div className="corner top-left" />
                            <div className="corner top-right" />
                        </TableContainer>
                    </StyledCard>
                )}
            </Container>
            <BottomButton status={status} className="col-12 mx-0 row px-3">
                <StyledPrimaryButton
                    className="mt-3"
                    onClick={() => submit()}
                    disabled={!checked.length}
                    loading={proccessing}
                >
                    Redeem
                </StyledPrimaryButton>
                <StyledPrimaryButton
                    className="mt-3"
                    onClick={() =>
                        navigate(
                            `/qr/cart?id=${cartUrlData.id}&s=${
                                cartUrlData.s
                            }&bei=${cartUrlData.bei || cartUrlData.id}`,
                        )
                    }
                >
                    Buy More
                </StyledPrimaryButton>
            </BottomButton>
        </div>
    )
}
const BottomButton = styled.div`
    position: fixed;
    bottom: 10px;
    .ant-btn {
        background-color: ${({ status }) =>
            status === 'success'
                ? '#35C053'
                : status === 'fail'
                ? '#FF384E'
                : status === 'warning'
                ? '#FF9838'
                : '#FF384E'};
    }
`
