import { CommonUtility, APIPath } from 'utility'
import { BaseService } from './base'

class AssignCoupon {

  addAssignCoupon(filter) {
    const data = CommonUtility.objectToParams(filter)
    return BaseService.post(`${APIPath.GTAssignCoupon}?${data}`)
  }

  getAssignedCoupons(filter) {
    const data = CommonUtility.objectToParams(filter)
    return BaseService.get(`${APIPath.GTGetAssignCoupon}?${data}`);
  }

  deleteAssignedCoupon(providerEventUId, couponId, createdBy) {
    const url = `${APIPath.GTDeleteAssignedCoupon}?providerEventUId=${providerEventUId}&couponId=${couponId}&createdBy=${createdBy}`;
    return BaseService.delete(url);
  }
}
const AssignCouponService = new AssignCoupon()
Object.freeze(AssignCouponService)
export { AssignCouponService }
