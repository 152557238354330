export const NotificationStatus = {
    success: 'SUCCESS',
    error: 'ERROR',
}

export const NotificationText = {
    eventAdd: 'Event Added Successfully',
    eventUpdate: 'Event Updated Successfully',
    imageAdded: 'Images Added',
    placeAdded: 'Place Address Added Sucessfully',
    defaultError: 'Something Went Wrong!',
    removed: 'Removed Sucessfully',
    profileUpdate: 'Profile Update Sucessfully',
    ItemAdded: 'Item Added',
    MaxLimitReached: 'Max Order Limit Reached',
    QuantityAdded: 'Quantity Added',
    QuantityRemoved: 'Quantity Removed',
    AllQuntityRemoved: 'All Quntity Removed',
    ItemRemoved: 'Item Removed',
    UserVerified: 'User Verified',
    EmailCodeInvalid: 'Email/Code is invalid',
    AllreadyBuyIt: 'You allready buy it in past',
    TNCIsRequired: 'Please Accept Terms & Condition',
    ResetPasswordLinkSend: 'Reset password link sended to your email.',
    LimitExceed: 'Avialble tickets limit is exceed',
}
