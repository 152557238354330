import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AddIcon from '../../assets/Icons/add.svg';
import styled from 'styled-components';
import MinusIcon from '../../assets/Icons/minus.svg';
import '../../screens/Css/BuyTicketModal.css';
import { PushNotification } from '../../components/Common';
import { AuthPopup, CommonUtility, NotificationStatus } from 'utility';
import { GetPublicEventDetails } from 'hooks';
import { Loader } from 'components';
import { BrowserUtility } from 'utility/browser-utility';
import { Skeleton } from 'antd';
import { useAuth } from 'context';
import { useLayout } from 'layout';

const TicketMain = styled.div`
    padding-top: 20px;
    width: 90%;
    margin: 0 auto 50px;

    .heading-main {
        display: flex;
        flex-direction: column;
        margin: 0px auto 20px;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .heading {
        font-weight: 700;
        font-size: 24px;
        padding-bottom: 15px;
    }

    @media (max-width: 480px) {
        padding-top: 15px;
        width: 95%;
        
        .heading-main {
            margin: 0px 10px;
        }
        
        .heading {
            font-size: 20px;
        }
    }

    @media (min-width: 481px) and (max-width: 768px) {
        padding-top: 20px;
        width: 90%;

        .heading-main {
            margin: 0px 15px;
        }

        .heading {
            font-size: 22px;
        }
    }

    @media (min-width: 769px) and (max-width: 1000px) {
        padding-top: 30px;
        width: 85%;

        .heading-main {
            margin: 0px 20px;
        }

        .heading {
            font-size: 22px;
        }
    }

    @media (min-width: 1001px) and (max-width: 1200px) {
        padding-top: 40px;
        width: 80%;

        .heading-main {
            margin: 0px 10px;
        }

        .heading {
            font-size: 24px;
        }
    }
`;

const ModalContainer = styled.div`
    overflow-x: visible;
    width: 77%;
    border-radius: 20px;
    margin: 0 auto;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    .buy-ticket-section {
        border-radius: 20px;
        margin: 0 auto;
        width: 100%;
    }
    @media (min-width: 200px) and (max-width: 480px) {
        width: 100%;
    }
    @media (min-width: 481px) and (max-width: 768px) {
        width: 100%;
    }
    @media (min-width: 769px) and (max-width: 1000px) {
        width: 90%;
    }
    @media (min-width: 1001px) and (max-width: 1200px) {
        width: 100%;
    }
`;
const ModalWrapper = styled.div`
    background: #242631;
    border-radius: 14px;
    padding: 13px 20px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .item-name {
        position: relative;
    }

    .tooltip-text {
        visibility: hidden;
        width: 200px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        top: 24px;
        left: 0;
        right: 0;
    }

    .item-name:hover .tooltip-text {
        visibility: visible;
    }

    /* Apply changes only for mobile view */
    @media (max-width: 480px) {
        padding: 15px 10px;
        margin: 12px;
        flex-direction: column; /* Stack items vertically */
        justify-content: center;
        align-items: flex-start;

        .item-name {
            font-size: 14px;
            margin-bottom: 8px;
            text-align: left;
            word-wrap: break-word;
        }

        .item-price {
            margin-bottom: 12px; /* Add space between price and button */
        }
    }
`;

const OuterBox = styled.div`
    display: flex;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    padding: 5px;
    align-items: center;
    gap: 12px;
`;

const Icon = styled.div`
    width: 34px;
    height: 34px;
    background: rgba(138, 138, 138, 0.3);
    border-radius: 4px;
    display: grid;
    place-items: center;
    cursor: pointer;
`;

const SoldTicket = styled.div`
    padding: 6px 12px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    min-width: 110px;
    span {
        color: #ff384e;
    }
`;

const AddTicketBtn = styled.div`
    padding: 6px 12px;
    background: #ff384e;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    min-width: 110px;
`;

const TotalMain = styled.div`
    background: #242631;
    border-radius: 30px 30px 0px 0px;
    padding: 18px 20px;
    margin: 0 auto;
    width: 100%;
`;

const TotalFees = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const Tag = styled.div`
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
`;

const Amount = styled.div`
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    opacity: 0.6;
`;

const ServiceFees = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 2px dashed #8a8a8a48;
`;

const TotalAmount = styled.div`
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
`;

const Btn = styled.div`
    .back-btn {
        width: 220px;
        margin-right: 10px;
        padding: 12px 12px;
        background: #4a4c5e;
        border-radius: 12px;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        cursor: pointer;
    }
    .next-btn {
        width: 220px;
        margin-left: 10px;
        padding: 12px 12px;
        background: #ff384e;
        border-radius: 12px;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        cursor: pointer;
    }
    @media (min-width: 200px) and (max-width: 768px) {
        .back-btn {
            width: 350px;
            margin-right: 0px;
            margin-bottom: 10px;
            margin-top: 20px;
        }
        .next-btn {
            width: 350px;
            margin-left: 0px;
        }
    }
`;

export const BuyTicketPage = ({ onSubmit, loading }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, loading: eventLoading } = GetPublicEventDetails(id);
    const { EventTicketRate: ticketRate } = data;
    const { currencyCode, isAuthenticated, guest } = useAuth();
    const [tickets, setTicket] = useState({});
    const [totalPrice, setTotalPrice] = useState(0);
    const { toggleModal } = useLayout();
    const [serviceFees, setServiceFees] = useState(0);

    const addTicket = (ticket, qty) => {
        let tmp = tickets[ticket.EventTicketId] || {
            EventTicketId: ticket.EventTicketId,
            TicketPrice: ticket.TicketPrice,
            ServiceFee: ticket.ServiceFee,
            MaxPerOrder: ticket.MaxPerOrder,
            TicketQty: ticket.TicketQty,
            IsSeatOption: ticket.IsSeatOption,
            Qty: 0,
        };
        tmp.Qty += qty;
        if (tmp.Qty === 0) {
            tmp = null;
        } else if (tmp.Qty > tmp.TicketQty) {
            tmp.Qty = tmp.TicketQty;
        }
        if (tmp && tmp.Qty > tmp.MaxPerOrder) {
            tmp.Qty = tmp.MaxPerOrder;
        }
        setTicket({ ...tickets, [ticket.EventTicketId]: tmp });
    };

    const clickOnNext = async () => {
        onSubmit(tickets, ticketRate);
    };

    const clickOnBack = () => {
        navigate(-1);
    };

    const goToNext = () => {
        const data = Object.values(tickets || {}).filter(d => d);
        if (data.length > 0) clickOnNext(data);
        else PushNotification('Please select ticket', NotificationStatus.error);
    };

    const onNext = () => {
        if (isAuthenticated || guest) {
            goToNext();
        } else {
            toggleModal(AuthPopup.login, goToNext, true);
        }
    };

    useEffect(() => {
        const data = BrowserUtility.getObj('selected-tickets');
        setTicket(data || {});
    }, []);

    useEffect(() => {
        const price = Object.values(tickets || {}).reduce(
            (a, b) => a + (b ? b.Qty * b.TicketPrice : 0),
            0,
        );
        const fees = Number(
            Object.values(tickets || {})
                .reduce((a, b) => a + (b ? b.Qty * b.ServiceFee : 0), 0)
                .toFixed(2),
        );
        setTotalPrice(price);
        setServiceFees(fees);
        BrowserUtility.saveObj('selected-tickets', tickets);
    }, [tickets]);

    if (eventLoading) return <BuyTicketSkeleton />;

    return (
        <TicketMain>
            <div className="heading-main">
                {data?.SpecialMessage && (
                    <div className="announcement-container">
                        <h4 className="announcement-title">Special Message</h4>
                        <p>{data.SpecialMessage}</p>
                    </div>
                )}
                <div className="heading">Ticket Summary</div>
            </div>
            <ModalContainer>
                <div className="row buy-ticket-section">
                    {ticketRate?.map(ticket => (
                        <div key={`${ticket.EventTicketId}`} className="col-sm-12 col-md-6">
                            <ModalWrapper>
                                <div className="item">
                                    <div className="item-name">
                                        {ticket.TicketType}
                                        {ticket.TicketDescription && (
                                            <>
                                                <span className="font-grey">
                                                    &nbsp;{' - '}&nbsp;{ticket.TicketDescription}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <div className="item-price">
                                        <span>
                                            {CommonUtility.currencyFormat(
                                                ticket.TicketPrice,
                                                currencyCode,
                                            )}
                                        </span>
                                    </div>
                                </div>
                                {tickets[ticket.EventTicketId] && (
                                    <div className="qty-selector">
                                        <OuterBox>
                                            <Icon>
                                                <img
                                                    src={MinusIcon}
                                                    onClick={() => addTicket(ticket, -1)}
                                                    alt=""
                                                />
                                            </Icon>
                                            <span>{tickets[ticket.EventTicketId].Qty}</span>
                                            <Icon>
                                                <img
                                                    src={AddIcon}
                                                    onClick={() => addTicket(ticket, 1)}
                                                    alt=""
                                                />
                                            </Icon>
                                        </OuterBox>
                                    </div>
                                )}
                                {!tickets[ticket.EventTicketId] &&
                                    (ticket.TicketQty <= 0 ? (
                                        <SoldTicket>
                                            <span>Sold Out</span>
                                        </SoldTicket>
                                    ) : (
                                        <AddTicketBtn onClick={() => addTicket(ticket, 1)}>
                                            <span>Add Ticket</span>
                                        </AddTicketBtn>
                                    ))}
                            </ModalWrapper>
                        </div>
                    ))}
                </div>
                <TotalMain>
                    <TotalFees>
                        <Tag>Total fees</Tag>
                        <Amount>
                            {CommonUtility.currencyFormat(totalPrice, currencyCode)}
                        </Amount>
                    </TotalFees>
                    <ServiceFees>
                        <Tag>Service fees</Tag>
                        <Amount>
                            {CommonUtility.currencyFormat(serviceFees, currencyCode)}
                        </Amount>
                    </ServiceFees>
                    <TotalAmount>
                        <Tag>Total amount</Tag>
                        <Amount>
                            {CommonUtility.currencyFormat(
                                (totalPrice || 0) + (serviceFees || 0),
                                currencyCode,
                            )}
                        </Amount>
                    </TotalAmount>
                    <Btn className="row justify-content-center pt-2">
                        <div className="back-btn" onClick={clickOnBack}>
                            <span>Back</span>
                        </div>
                        <div className="next-btn" onClick={onNext}>
                            <span>Next</span>
                        </div>
                    </Btn>
                </TotalMain>
                <Loader loading={loading} />
            </ModalContainer>
        </TicketMain>
    );
};

const BuyTicketSkeleton = () => {
    return (
        <TicketMain>
            <div className="heading-main">
                <div className="heading">Ticket Summary</div>
            </div>
            <ModalContainer>
                <div className="row buy-ticket-section">
                    <TicketSkeleton />
                    <TicketSkeleton />
                </div>
                <PriceSummarySkeleton />
            </ModalContainer>
        </TicketMain>
    );
};

const TicketSkeleton = () => {
    return (
        <div className="col-sm-12 col-md-6">
            <ModalWrapper>
                <div className="item">
                    <Skeleton.Input active />
                </div>
                <Skeleton.Button active />
            </ModalWrapper>
        </div>
    );
};

const PriceSummarySkeleton = () => {
    return (
        <TotalMain>
            <TotalFees>
                <Skeleton.Input active />
                <Skeleton.Input active />
            </TotalFees>
            <ServiceFees>
                <Skeleton.Input active />
                <Skeleton.Input active />
            </ServiceFees>
            <TotalAmount>
                <Skeleton.Input active />
                <Skeleton.Input active />
            </TotalAmount>
            <Btn className="row justify-content-center pt-2">
                <div className="back-btn">
                    <Skeleton.Input active />
                </div>
                <div className="next-btn">
                    <Skeleton.Input active />
                </div>
            </Btn>
        </TotalMain>
    );
};
