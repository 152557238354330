import { Modal, Spin } from 'antd';
import { Buttons, OutlinedButton, PrimaryButton } from 'elements';
import React, { useState } from 'react';
import styled from 'styled-components';

const ModelStyled = styled(Modal)`
    .ant-modal-content {
        background-color: #1e1e1e !important;
        border-radius: 10px;
        padding: 10px; /* Reduced padding */
    }

    .ant-modal-header {
        border-bottom: none;
        background-color: #1e1e1e;
        padding-bottom: 0;
    }

    .ant-modal-body {
        background-color: #1e1e1e;
        padding: 10px 20px;
        max-height: 75vh; /* Set max height for content */
        overflow-y: auto; /* Allow scrolling for longer content */
    }

    .ant-modal-title {
        color: #fff;
        font-size: 20px; /* Reduced font size */
        font-weight: bold;
        text-align: center; /* Center the title */
        margin-bottom: 15px;
    }

    .ant-input {
        height: 40px !important; /* Reduced height */
        font-size: 14px !important; /* Reduced font size */
        padding-left: 10px !important;
        background-color: #333 !important;
        color: #fff !important;
        border: 1px solid #555 !important;
        border-radius: 6px !important;
        margin-bottom: 10px !important; /* Added margin for spacing */
    }

    .ant-checkbox-wrapper {
        color: #fff;
    }

    .ant-checkbox-inner {
        background-color: #2b2b2b;
        border-color: #fff;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #ff6700;
        border-color: #ff6700;
    }

    .small-text {
        color: #bbb;
        font-size: 14px;
        margin-top: 5px;
    }

    .continue-button {
        background-color: #ff5252;
        color: #fff;
        width: 100%; /* Full width for Continue as Guest */
        height: 50px;
        font-size: 16px;
        border-radius: 9px;
        margin-top: 10px;
    }

    .modal-footer-buttons {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        padding: 10px 0;

        @media (max-width: 480px) {
            gap: 8px;
        }
    }

    .submit-button,
    .cancel-button {
        width: 47%; /* Equal width for Submit and Cancel */
        height: 45px;
        font-size: 14px;
        border-radius: 6px;

        @media (max-width: 480px) {
            width: 100%; /* Full width on mobile */
        }
    }

    /* Responsive modal size for mobile */
    @media (max-width: 480px) {
        .ant-modal-content {
            width: 95%; /* Make modal width smaller for mobile screens */
            margin: 0 auto;
        }

        .ant-modal-title {
            font-size: 18px;
        }

        .ant-input {
            font-size: 12px;
            height: 38px;
        }

        .continue-button,
        .submit-button,
        .cancel-button {
            font-size: 14px;
            height: 40px;
        }
    }
`;

const Spiner = styled(Spin)`
    .ant-spin-dot-item {
        background-color: ${({ theme }) => theme.colors.danger} !important;
    }
`;

export const Loader = ({ loading, size = 'middle' }) =>
    loading && <Spiner spinning size={size} />;

export const PopUpModalContainer = ({
    children,
    onCancel,
    onSubmit,
    width,
    wantSkip = false,
    onSkip,
    ...rest
}) => {
    const [loading, setLoading] = useState(false);

    const handleSkip = async () => {
        setLoading(true);
        if (onSkip) {
            await onSkip();
        }
        setLoading(false);
    };

    return (
        <ModelStyled
            className="custom-modal"
            footer={[
                wantSkip && (
                    <Buttons
                        onClick={handleSkip}
                        key="skip"
                        className="continue-button"
                    >
                        {loading ? (
                            <Spiner size="small" />
                        ) : (
                            'CONTINUE AS GUEST'
                        )}
                    </Buttons>
                ),
                <div className="modal-footer-buttons" key="footer-buttons">
                    <PrimaryButton onClick={onSubmit} className="submit-button">
                        SUBMIT
                    </PrimaryButton>
                    <OutlinedButton
                        onClick={() => onCancel(false)}
                        className="cancel-button"
                    >
                        CANCEL
                    </OutlinedButton>
                </div>,
            ]}
            onCancel={onCancel}
            width={width || 400} /* Reduced width */
            {...rest}
        >
            {children}
        </ModelStyled>
    );
};
