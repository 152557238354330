import { useAuth } from 'context'
import { useState, useEffect } from 'react'
import { ProductService } from 'utility'

export const GetProductLibrary = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const { profile } = useAuth()
    const [hasMore, setHasMore] = useState(false)
    const [filter, setFilter] = useState({
        pageNumber: 1,
        pageSize: 9,
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await ProductService.getProduct(profile?.Id, {
                    ...filter,
                })
                if (filter.pageNumber === 1) {
                    setData(result.Result.Items)
                } else {
                    setData([...data, ...result.Result.Items])
                }
                setHasMore(result.Result.Items.length === filter.pageSize)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }

        if (!loading && profile?.Id) {
            fetchData()
        }
    }, [filter, profile])

    const filterChanged = changeValues => {
        setFilter({
            ...changeValues,
            pageSize: 9,
            pageNumber: 1,
        })
    }
    const fetchMoreData = () => {
        setFilter({
            ...filter,
            pageNumber: filter.pageNumber + 1,
        })
    }

    const refetch = () => {
        setFilter({
            pageSize: 9,
            pageNumber: 1,
        })
    }

    return {
        data,
        error,
        setData,
        loading,
        fetchMoreData,
        hasMore,
        filterChanged,
        refetch,
    }
}

export const GetProductLibraryById = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [filter, setFilter] = useState({
        ProductUId: 0,
    })

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await ProductService.getProductById({
                ...filter,
            })
            setData(result.Result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!loading && filter.ProductUId) {
            fetchData()
        }
    }, [filter])

    const filterChanged = changeValues => {
        setFilter({
            ...changeValues,
        })
    }

    return {
        data,
        error,
        loading,
        filterChanged,
    }
}
