import { useState, useEffect } from 'react'
import { BrowserUtility, BusinesService } from 'utility'

export const GetBusinessCategoryList = (type, id) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await BusinesService.get(type, id)
            setData(result.Result)
        } catch (err) {
            setError(err.message || 'An error occurred')
            BrowserUtility.remove('selected-items')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [type, id])

    const refetch = () => {
        fetchData()
    }

    return {
        data,
        error,
        setData,
        loading,
        refetch,
    }
}

export const GetProductPublicKey = (type, TicketBucketId) => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await BusinesService.getPublicKey(
                type,
                TicketBucketId,
            )
            setData(result?.Result)
            BrowserUtility.saveObj('product-public-key', result?.Result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        fetchData()
        const details = BrowserUtility.getObj('product-public-key')
        if (details) setData(details)
    }, [type, TicketBucketId])

    return {
        data,
        error,
        loading,
        fetchData,
    }
}

export const ProductBuyNow = (type, id) => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await BusinesService.buyNow(type, id)
            setData(result?.Result)
            BrowserUtility.saveObj(`product-buy-now-${id}`, result?.Result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (id) {
            const details = BrowserUtility.getObj(`product-buy-now-${id}`)
            if (!details) fetchData()
            else setData(details)
        }
    }, [type, id])

    return {
        data,
        error,
        loading,
        fetchData,
    }
}
