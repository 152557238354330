import { Alert } from 'antd'
import { PushNotification } from 'components'
import { BuyTicketPage } from 'page-components/ticket'
import { Copy } from 'phosphor-react'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { NotificationStatus, NotificationText, TicketService } from 'utility'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { GetPublicEventDetails } from 'hooks'

const AlertBox = styled(Alert)`
    background-color: #f6ffed00;
`

export const CreatePaymentScreen = () => {
    const [loading, setLoading] = useState(false)
    const { id } = useParams()
    const [link, setLink] = useState('')
    const { data: event } = GetPublicEventDetails(id)
    console.log(
        '🚀 ~ file: create-payment-screen.js:21 ~ CreatePaymentScreen ~ event:',
        event,
    )

    console.log(
        '🚀 ~ file: create-payment-screen.js:10 ~ CreatePaymentScreen ~ id:',
        id,
    )
    const onSubmit = async tickets => {
        const data = Object.values(tickets || {}).filter(d => d)
        if (data.length === 0) return
        const params = data.map(ticket => ({
            EventTicketId: ticket.EventTicketId,
            Quantity: ticket.Qty,
        }))
        setLoading(true)
        try {
            const result = await TicketService.createLink(
                event.ExpressCheckoutLinkId,
                params,
            )
            console.log(
                '🚀 ~ file: create-payment-screen.js:43 ~ onSubmit ~ result:',
                result,
            )
            setLink('llkl')
            setLoading(false)
        } catch (error) {
            console.log(error)
            PushNotification(
                error?.error?.ErrorMessage || NotificationText.defaultError,
                NotificationStatus.error,
            )
            setLink('')
            setLoading(false)
        }
    }
    return (
        <div>
            {link ? (
                <AlertBox
                    message={link}
                    type="success"
                    action={
                        <CopyToClipboard
                            onCopy={() =>
                                PushNotification(
                                    'Link copied',
                                    NotificationStatus.success,
                                )
                            }
                            text={link}
                        >
                            <Copy size={25} />
                        </CopyToClipboard>
                    }
                />
            ) : (
                <BuyTicketPage onSubmit={onSubmit} loading={loading} />
            )}
        </div>
    )
}
